import { Component, OnInit } from '@angular/core';
import { ServicioPopupService } from '../servicio-popup.service';
import { Mensaje } from "@mensaje";
import { SettingsList } from '@settingsList';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';



@Component({
  selector: 'app-popup-resumen',
  templateUrl: './popup-resumen-lista.component.html',
  styleUrls: ['./popup-resumen-lista.component.scss']
})
export class PopupResumenListaComponent implements OnInit {

  //variables para configuracion de popup
  camposSet: any = {};
  modalRef: any;
  modelo: any;
  mensaje: any;
  resultado: any;
  bloqueoBoton: any = false;
  esLectura: boolean = false;
  titulo: string = "";

  
  rows: any;
  columns: any;
  filter: any;
  configuracionLista = new SettingsList();

  //Lista de Informacion Casetas
  datosConfiguracionListaPopup = {
    sigmaConsultaPaginacion: "",
    sigmaConsulta: "",
    filtroSigmaConsultaPaginacion: "",
    filtroSigmaConsulta: "",
  };
  // '[{"prop":"resumen","name":"Resumen"}]'
  temp_configuracionColumnasPopup: any;

  constructor(
    private servicio: ServicioPopupService,
    public utilidades: ServicioUtilidadesService
  ) {
    this.mensaje = new Mensaje("", "", "");
  }

  ngOnInit() {
    this.configuracionLista.paging = true;
    this.SetearCampos();
    if(this.temp_configuracionColumnasPopup[0].prop != undefined){
      this.columns = this.temp_configuracionColumnasPopup;
    }else{
      this.columns = JSON.parse(this.temp_configuracionColumnasPopup);
    }
    this.rows = [];
    if(this.configuracionLista.paging){
      this.ObtenerCantidadElementos();
    }
  }

  SetearCampos() {
    this.titulo = this.camposSet["titulo"];
    this.datosConfiguracionListaPopup.sigmaConsultaPaginacion = this.camposSet["sigmaConsultaPaginacion"];
    this.datosConfiguracionListaPopup.filtroSigmaConsultaPaginacion = this.camposSet["filtroSigmaConsultaPaginacion"];
    this.datosConfiguracionListaPopup.sigmaConsulta = this.camposSet["sigmaConsulta"];
    this.datosConfiguracionListaPopup.filtroSigmaConsulta = this.camposSet["filtroSigmaConsulta"];
    if (this.camposSet["configuracionColumnas"]) {
      this.temp_configuracionColumnasPopup = this.camposSet["configuracionColumnas"];
    }else{
      this.temp_configuracionColumnasPopup = '[{"prop":"resumen","name":"Resumen"}]';
    }
    if(this.camposSet["paginacion"]){
      this.configuracionLista.paging = this.camposSet["paginacion"];
    }
    if(this.camposSet["configuracionLista"] != undefined && this.camposSet["configuracionLista"] !=""){
      this.configuracionLista = this.camposSet["configuracionLista"];
    }
  }

  confirmarSeleccion() {
    let parametros = {
      contenedor: this
    };
    //Muestra un modal para asegurar la accion que quiere realizar el usuario
    this.mensaje.retornarMensajeFuncion("confirmacion", "¿Desea agregar el registro seleccionado?", "", "", this, "retornarResultado", parametros);
  }

  retornarResultado(parametros) {
    parametros.contenedor.modalRef.close(parametros.contenedor.resultado);
  }

  cerrarModal() {
    this.modalRef.close();
  }

  /**
   * Recibe el Modelo cuando se da clic al grid
   * @author Andrés Quintero
   * @date 2019-07-05.
   * @param objeto elemento seleccionado
  */
  ObjetoListaSeleccionadoPopup(objeto) {
    let registro
    if(objeto.selected.length >1){
       registro = objeto.selected;
       this.bloqueoBoton = true;
    }else{
      registro = objeto.selected[0]
      this.bloqueoBoton = true;
    }
    if(objeto.selected[0] == undefined){
      this.bloqueoBoton = false;
    }
    this.resultado = registro;
  }



  /*
  Realiza la consulta de la cantidad total de elementos 
  */
  ObtenerCantidadElementos() {
    this.configuracionLista.loading = true;
    let idSigmaConsulta = this.datosConfiguracionListaPopup.sigmaConsultaPaginacion;
    let filtro = this.datosConfiguracionListaPopup.filtroSigmaConsultaPaginacion;
    filtro = JSON.parse(filtro);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "")
      .subscribe(res => {
        if (res.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
          this.configuracionLista.totalElements = respuesta[0].total;
          this.configuracionLista = { ...this.configuracionLista };

          this.SetData({ offset: 0 });
        }
      });
  }

  /*
  Trae los elementos paginando desde el servidor
  */
  SetData(pageInfo) {
    this.configuracionLista.pageNumber = pageInfo.offset;
    const limiteAux = this.configuracionLista.size;
    const offset = this.configuracionLista.pageNumber;
    let idSigmaConsulta = this.datosConfiguracionListaPopup.sigmaConsulta;
    let filtro = this.datosConfiguracionListaPopup.filtroSigmaConsulta;
    filtro = JSON.parse(filtro);
    filtro["limite"] = String(limiteAux);
    filtro["offset"] = String(offset);
    if (this.filter) {
      filtro["filtro"] = `%${this.filter}%`;
    }
    filtro = JSON.stringify(filtro);
    this.servicio.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "").subscribe(res => {
      if (res.respuesta == "true") {
        let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
        let coleccionDatosLista = [];

        for (let i in respuesta) {
          if (respuesta[i].resumen) {
            respuesta[i].resumen = respuesta[i].resumen.replace(/@%@/g, "<br>");
          }
          coleccionDatosLista.push(respuesta[i]);
        }
        this.rows = coleccionDatosLista;
	   
      }else{
        this.rows = [];
      }
      this.configuracionLista.loading = false;
    });
  }


  /*
  Filtra la lista haciendo la consulta a base de datos
  */
  Filter(filter) {
    this.filter = filter;
    this.ObtenerCantidadElementos();
  }

}
