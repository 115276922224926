import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from '@seguridad';
import { tap } from 'rxjs/operators';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';

@Injectable({
  providedIn: 'root'
})
export class ServicioValidarCoorSancioService {

  header: any = {};

  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad,
    public utilidades: ServicioUtilidadesService
  ) {

  }


  getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  getServicioSigmaConsultaFiltroUsuario(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacionFiltroUsuario",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }


  getServicioObtenerInformacionCache(sistema, idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioPizarra"] + "/FUNDatosCache/ConsultarInformacionCache",
      {
        sistema: sistema,
        idObjetoPizarra: idObjetoPizarra,
        filtro: filtro,
        datosPeticion: datosPeticion

      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );


  }


  getServicioRealizarAccion(sistema, nombreObjeto, jsonDatos, jsonDatosPeticion, accion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlServicioSancionatorio"] + "/FUNSancionatorio/RealizarAccion",
      {
        sistema: sistema,
        nombreObjeto: nombreObjeto,
        jsonDatos: jsonDatos,
        jsonDatosPeticion: jsonDatosPeticion,
        accion: accion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }

  subirArchivo(fd): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNArchivo/SubirArchivosFtp",
      fd,
      { headers: new HttpHeaders({ "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }


  getFormato(fd) {
    this.seguridadGlobal.validarSesion();
    this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["urlFormato"] + "Combinar_G.php",
      fd
    ).subscribe(
      (res: Response) => {
        console.log(res);
      },
      err => {
        console.log("Error occured", err);
      }
    );
  }

  descargarArchivo(url, param):  Observable<any> {
    this.seguridadGlobal.validarSesion();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Content-Types": this.seguridadGlobal.atributoSesion
    });
    return this.http.post<Blob>(url,
      param, {headers: headers, responseType: 'blob' as 'json' });
  }




}
