import { ActionReducerMap } from '@ngrx/store';
import { AppStore } from './app-state-store';
import { IAPPState } from './estados/estados';
const appStore: AppStore = new AppStore();
export const appStateReducers: ActionReducerMap<IAPPState> = {
    modulos: appStore.reducerEventosModule,
    rutas: appStore.reducerEventosRutas,
    operations: appStore.reducerEventosOperaciones,
    filterData: appStore.reducerEventosFiltro
};
