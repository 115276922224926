/**
 * @description Carga la configuración y actualiza la información de los subsistemas que ingresan como input
 * @author Lukas Mesa Buriticá
 * @date 22/04/2020
 */
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { AppSelector, AppStore } from '@shared/store/app-state-store';
import { IAPPState, ISigmaConsulta, ISubsistema } from '@shared/store/estados/estados';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-subsistemas-v3',
    templateUrl: './subsistemas-v3.component.html',
    styleUrls: ['./subsistemas-v3.component.scss'],
})
export class SubsistemasV3Component implements OnInit, OnDestroy {
    @ViewChild('myCarousel') myCarousel: NguCarousel<NguCarouselStore>;
    @Input() moduloID: string;
    carouselConfig: NguCarouselConfig;
    coleccionSubsistemas$: Observable<Array<ISubsistema>>;
    subsistemaActual$: Observable<{ data: ISubsistema; action: any }>;
    clase: string = 'SubsistemasComponent';

    private appStore: AppStore;
    private appSelector: AppSelector;
    private subSigmaConsulta: Subscription;
    private operacion: ISigmaConsulta;
    constructor(private store: Store<IAPPState>) {
        this.appStore = new AppStore();
        this.appSelector = new AppSelector();
    }

    ngOnDestroy(): void {
        this.subSigmaConsulta?.unsubscribe();
    }

    /**
     * @description Carga la configuración del carrusel
     * @author Lukas Mesa Buriticá
     * @date 22/04/2020
     */
    ngOnInit() {
        try {
            this.carouselConfig = {
                grid: { xs: 3, sm: 3, md: 3, lg: 3, all: 0 },
                slide: 1,
                // speed: 900,
                // interval: 4000,
                animation: 'lazy',
                point: {
                    visible: false,
                    hideOnSingleSlide: false,
                },
                load: 2,
                touch: true,
                loop: true,
                custom: 'banner',
            };
            this.coleccionSubsistemas$ = this.store.select(this.appSelector.getSubsistemas(), { ruta: this.moduloID, codigo: '' });
            this.subsistemaActual$ = this.store.select(this.appSelector.getSubsistema(), { ruta: this.moduloID, codigo: '' });
            this.subSigmaConsulta = this.store
                .select(this.appSelector.getSigmaConsultaSubsistemas(), { ruta: this.moduloID })
                .subscribe((operacion) => {
                    if (operacion != undefined && operacion.id != '' && JSON.stringify(this.operacion) != JSON.stringify(operacion)) {
                        this.operacion = operacion;
                        this.store.dispatch(this.appStore.cargarSubsistemas(operacion));
                    }
                });
        } catch (error) {
            console.log('Error: ' + this.clase + '->' + error);
        }
    }

    /**
     * @description Muestra u oculta la cantidad del subsistema que ingresa por parámetro
     * @author Lukas Mesa Buriticá
     * @date 22/04/2020
     */
    mostrarCantidad(elemento): boolean {
        try {
            let retorno = false;
            if (elemento.json.sub_moscan === 'si') {
                retorno = false;
            } else {
                retorno = true;
            }
            return retorno;
        } catch (error) {
            console.log('Error: ' + this.clase + '->' + error);
        }
    }

    /**
     * @description Valida si se debe reubicar el carrusel en la primera posición por cambio de objeto de control
     * @author Lukas Mesa Buriticá
     * @date 11/08/2020
     */
    cambioSubsistema(subsistema: ISubsistema) {
        this.store.dispatch(this.appStore.asignarSubsistema({ ruta: this.moduloID, subCodigo: subsistema.sub_codigo }));
    }
}
