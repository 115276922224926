import Swal from "sweetalert2";



export class Mensaje {
    public tipo: string;
    public titulo: string;
    public texto: string;
    public funcion;
    public parametros: JSON;
    public arrayMensajes: any;
    public mensajeCarga: any;

    constructor(tipo: string, titulo: string, texto: string) {
        this.tipo = tipo;
        this.titulo = titulo;
        this.texto = texto;
        this.arrayMensajes = JSON.parse(sessionStorage.getItem('arrayMensajes'));
    }

    buscarMensajes(codigo: string) {

        for (let key in this.arrayMensajes) {
            if (this.arrayMensajes[key].codigo == codigo) {
                let json = JSON.parse(this.arrayMensajes[key].json);
                this.retornarMensaje(json.tipo, json.titulo, json.texto, json.parametros);
            }
        }

    }

/// <summary>
    /// Creado por: David Pérez Arias
    /// Fecha: 27/08/2018
    /// Metodo que muestra un mensaje, en caso de utilizar el mensaje de confirmacion llama la funcion que es llamada
    /// </summary>
    /// <param></param>
    /// <returns></returns>
    retornarMensajeFuncion2(tipo: string, titulo: string, texto: string, objeto: any, nombreFuncion: any, parametrosFuncion: any, nombreFuncionCancelar: string) {
        var datos = "";
        switch (tipo) {
            case ("exito"):
                Swal.fire({ title: titulo, icon: 'success', text: texto, timer: 5000 });
                break;
            case "error":
                Swal.fire({ title: titulo, icon: 'error', text: texto });
                break;
            case ("warning"):
            case ("confirmacion"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: '',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
                        }
                    }
                    else if (!result.value){
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionCancelar + ""].call(null, parametrosFuncion);
                        }
                    } 
                })
                break;
            case ("loading"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    allowOutsideClick: false,
                    onOpen: () => {
                        Swal.showLoading();
                        this.mensajeCarga = Swal;
                    },
                });
                break;
                case ("confirmacionok"):
                    Swal.fire({
                        title: titulo,
                        text: texto,
                        icon: 'success',
                        html: '',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar'
                    }).then((result) => {
                            nombreFuncion();
                    })
                    break;
                    case ("confirmacionerror"):
                        Swal.fire({
                            title: titulo,
                            text: texto,
                            icon: 'warning',
                            html: '',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Aceptar'
                        }).then((result) => {
                            nombreFuncion();
                        })
                        break;
                case ("confirmacionNuevo"):
                    Swal.fire({
                        title: titulo,
                        text: texto,
                        icon: 'warning',
                        html: '',
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Aceptar'
                    }).then((result) => {
                        if(result.isConfirmed){
                            nombreFuncion();
                        }
                    })
                break;
            default:
                Swal.fire({ title: titulo, text: texto });
                break;
        }
    }

    /// <summary>
    /// Creado por: Victor Bedoya
    /// Fecha: 24/07/2018
    /// Metodo que muestra un mensaje, en caso de utilizar el mensaje de confirmacion llama la funcion que es llamada
    /// </summary>
    /// <param></param>
    /// <returns></returns>
    retornarMensaje(tipo: string, titulo: string, texto: string, parametros: string, textoHtml?: string) {
        const datos = '';
        switch (tipo) {
            case 'success':
                Swal.fire({ title: titulo, icon: 'success', text: texto });
                break;
            case 'exito':
                if (textoHtml !== null && textoHtml !== undefined) {
                    Swal.fire({ title: titulo, icon: 'success', text: '', html: textoHtml, timer: 5000 });
                } else {
                    Swal.fire({ title: titulo, icon: 'success', text: texto, timer: 5000 });
                }
                this.mensajeCarga = Swal;
                break;
            case 'error':
                if (textoHtml !== null && textoHtml !== undefined) {
                    Swal.fire({ title: titulo, icon: 'error', text: '', html: textoHtml, timer: 5000 });
                } else {
                    Swal.fire({ title: titulo, icon: 'error', text: texto, timer: 5000 });
                }
                this.mensajeCarga = Swal;
                break;
            case 'warning':
            case 'confirmacion':
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    if (result.value) {
                        if (parametros != undefined && parametros != '') {
                            // this.funcion.call(null, parametros);
                            // Swal.fire({ title: 'Correcto', icon: 'success', text: 'Accion Realizada con Exito', timer: 3000 })
                        }
                    } else {
                    }
                });
                break;
            default:
                Swal.fire({ title: titulo, text: texto });
                break;
        }
    }

    /// <summary>
    /// Creado por: David Pérez Arias
    /// Fecha: 27/08/2018
    /// Metodo que muestra un mensaje, en caso de utilizar el mensaje de confirmacion llama la funcion que es llamada
    /// </summary>
    /// <param></param>
    /// <returns></returns>
    retornarMensajeFuncion(tipo: string, titulo: string, texto: string, textoHtml: string, objeto: any, nombreFuncion: String, parametrosFuncion: any) {
        var datos = "";
        switch (tipo) {
            case ("success"):
            case ("exito"):
                Swal.fire({ title: titulo, icon: 'success', text: texto, timer: 5000 });
                break;
            case "error":
                Swal.fire({ title: titulo, icon: 'error', text: texto });
                break;
            case ("warning"):
            case ("confirmacion"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
                        }
                    }
                })
                break;
            case ("confirmacionSiNo"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
                        }
                    }
                })
                break;
            case ("loading"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    allowOutsideClick: false,
                    onOpen: () => {
                        Swal.showLoading();
                        this.mensajeCarga = Swal;
                    }
                })
                break;
            default:
                Swal.fire({ title: titulo, text: texto });
                break;
        }
    }

    /// <summary>
    /// Creado por: David Pérez Arias
    /// Fecha: 27/08/2018
    /// Metodo que muestra un mensaje, en caso de utilizar el mensaje de confirmacion llama la funcion que es llamada
    /// </summary>
    /// <param></param>
    /// <returns></returns>
    mensajeFuncionSiNo(tipo: string, titulo: string, texto: string, textoHtml: string, objeto: any, nombreFuncion: String, nombreFuncionNo: String, parametrosFuncion: any) {
        var datos = "";
        switch (tipo) {
            case ("success"):
            case ("exito"):
                Swal.fire({ title: titulo, icon: 'success', text: texto, timer: 5000 });
                break;
            case "error":
                Swal.fire({ title: titulo, icon: 'error', text: texto });
                break;
            case ("warning"):
            case ("confirmacion"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
                        }
                    }
                })
                break;
            case ("confirmacionSiNo"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
                        }
                    } else {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionNo + ""].call(null, parametrosFuncion);
                        }
                    }
                })
                break;
            case ("loading"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    allowOutsideClick: false,
                    onOpen: () => {
                        Swal.showLoading();
                        this.mensajeCarga = Swal;
                    }
                })
                break;
            default:
                Swal.fire({ title: titulo, text: texto });
                break;
        }
    }

    retornarMensajeFuncionCancelacion(tipo: string, titulo: string, texto: string, textoHtml: string, objeto: any, nombreFuncionUno: String, parametrosFuncionUno: any, nombreFuncionDos: String, parametrosFuncionDos: any) {
        var datos = "";
        switch (tipo) {
            case ("warning"):
            case ("confirmacion"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionUno + ""].call(null, parametrosFuncionUno);
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionDos + ""].call(null, parametrosFuncionDos);
                        }
                    }
                })
                break;
            case ("confirmacionSiNo"):
                Swal.fire({
                    title: titulo,
                    text: texto,
                    icon: 'warning',
                    html: textoHtml,
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.value) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionUno + ""].call(null, parametrosFuncionUno);
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        if (objeto != undefined && objeto != null) {
                            objeto["" + nombreFuncionDos + ""].call(null, parametrosFuncionDos);
                        }
                    }
                })
                break;
            default:
                Swal.fire({ title: titulo, text: texto });
                break;
        }
    }


    /// <summary>
    /// Creado por: Juan David Cardona
    /// Fecha: 21/08/2018
    /// Metodo que muestra cierra el mensaje de carga
    /// </summary>
    /// <param></param>
    /// <returns></returns>
    cerrarMensajeCarga() {
        this.mensajeCarga.close();
    }

    mensajeNotificacion(position: string, tipo: string, texto: string) {
        let valores: any = {
            toast: true,
            position: position,
            showConfirmButton: false,
            timer: 3500
        }
        let valores2: any = {
            icon: tipo,
            title: texto
        }

        let toast = Swal.mixin(valores);
        toast.fire(valores2);
        /*
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });

          toast({
            icon: 'success',
            title: 'Signed in successfully'
          });
          */
    }
	
	
	/// <summary>
  /// Creado por: Esteban Muñoz
  /// Fecha: 2020-09-17
  /// Metodo que muestra el mensaje solicitando que archivo se va subir
  /// </summary>
  /// <param></param>
  /// <returns>true, false</returns>
  retornarMensajeValidacion(tipo: string, titulo: string, texto: string, textoHtml: string, mensajeConfirmacion: string, mensajeCancel: string, objeto: any, nombreFuncion: string, parametrosFuncion: any) {

    switch (tipo) {
      case ("success"):
      case ("exito"):
        Swal.fire({ title: titulo, icon: 'success', text: texto, timer: 5000 });

        break;
      case "error":
        Swal.fire({ title: titulo, icon: 'error', text: texto });

        break;
      case ("warning"):

      case ("confirmacion"):
        Swal.fire({
          title: titulo,
          text: texto,
          icon: 'warning',
          html: textoHtml,
          showCancelButton: true,
          cancelButtonText: mensajeCancel,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: mensajeConfirmacion
        }).then((result) => {

          if (result.value) {
            if (objeto != undefined && objeto != null) {
              parametrosFuncion["tipo"] = mensajeConfirmacion;
              objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
            }
          } else {
            if (objeto != undefined && objeto != null) {
              parametrosFuncion["tipo"] = mensajeCancel;
              objeto["" + nombreFuncion + ""].call(null, parametrosFuncion);
            }
          }
        })
        break;
      case ("loading"):
        Swal.fire({
          title: titulo,
          text: texto,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
            this.mensajeCarga = Swal;
          }
        })

        break;

      default:
        Swal.fire({ title: titulo, text: texto });

        break;
    }

  }

}
