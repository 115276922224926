import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ServicioResumenInformacionService } from './servicio-resumen-informacion.service';
import { ServicioModuloGeneralService } from '@shared/services/servicio-modulo-general.service';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';


@Component({
  selector: 'app-resumen-informacion',
  templateUrl: './resumen-informacion.component.html',
  styleUrls: ['./resumen-informacion.component.scss']
})
export class ResumenInformacionComponent implements OnInit, OnDestroy {

  selected: any[] = [];
  titulo: "";
  modelo: any = {};
  secciones: any = [];

  @Input() jso_llave: any;
  @Input() mapeo: any;

  constructor(
    private servicio: ServicioResumenInformacionService,
    public servicioGeneral: ServicioModuloGeneralService,
    public utilidades: ServicioUtilidadesService
  ) {
  }

  ngOnInit() {
    this.servicio.hijo = this;
    this.modelo = this.servicio.ObtenerModelo();
    if (this.modelo == undefined) {
      this.AsignarModelo(this.servicioGeneral.modelo);
    } else {
      this.AsignarModelo(this.modelo);
    }

  }

  ngOnChanges() {

  }

  InstanciarMapeo() {
    
    let filtro = "{'sistema':'" + sessionStorage.getItem("sistemaLogueado") + "','jso_llave':'" + this.jso_llave + "','mapeo':'" + this.mapeo + "'}";
    try {
      this.servicio.getServicioSigmaConsulta(sessionStorage.getItem("sistemaLogueado"), "GENERICO_RESUMEN_INFORMACION", filtro, "").subscribe(data => {
        if (data.respuesta == "true") {
          let respuesta = this.utilidades.RetornarStringObjeto(data.datos);
          this.secciones = respuesta.datos[0].mapeo;
        }
      });
    } catch (e) {
      console.log("Error: " + e);
    }

  }

  /**
   * Asigna el modelo seteado desde un servicio
   * @author Juan David Cardona
   * @date 2019-06-01
   * @param modelo
   * @returns Respuesta del servicio de la capa funcional
  */
  AsignarModelo(modelo: any) {
    try {
      this.InstanciarMapeo()
      if (modelo != undefined) {
        this.modelo = JSON.parse(JSON.stringify(modelo));
        let res = {};
        //this.AplanarJson(ejemplo,res);
        this.modelo = this.AplanarJson(this.modelo, res);
        // let a =this.AplanarJson2(ejemplo);
        this.servicioGeneral.modelo = this.modelo;
      } else {
        this.modelo = "";
        this.servicioGeneral.modelo = "";
      }
    } catch (error) {
      console.log("Error: " + error);
    }
  }

  /**
   * Asigna el modelo seteado desde un servicio
   * @author Juan David Cardona
   * @date 2019-06-01
   * @param modelo
   * @returns Respuesta del servicio de la capa funcional
  */
  AplanarJson(json, jsonRes) {
    for (let llave in json) {
      let valor = json[llave];
      try {
        valor = JSON.stringify(valor);
        valor = JSON.parse(valor);
        if (valor && typeof valor === "object") {
          this.AplanarJson(valor, jsonRes);
        } else {
          jsonRes[llave] = valor;
        }
      } catch (e) {
        jsonRes[llave] = valor;
      }
    }
    return jsonRes;
  }

  AplanarJson2(ob) {
    var toReturn = {};
    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if ((typeof ob[i]) == 'object') {
        var flatObject = this.AplanarJson2(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  ngOnDestroy(): void {
    this.servicio.modelo = undefined;
  }
}
