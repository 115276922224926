import { createAction, createSelector, props, createReducer, on, Action } from '@ngrx/store';
import { ILayer } from 'app/visor/visor-container/visor-widget-container/widget-data-table/Interfaces/ILayer';

import { IActualizar, IBounds, IDataTable, ILocate, IOpacity, ISeleccion, IVisor, IVisorState } from 'app/visor/shared/models/mapModels';
import { getVisorState } from '@leafletStore/selectors/config-leaflet.selector';

export class VisorStore {
    private estado: IVisor = {
        capaActualizar: { capaActualizar: '', time: 0 },
        dataTable: { layers: [], active: false },
        seleccion: [],
        operacion: '',
        locate: { locate: '', info: false, id: '', serNombre: '', serOrigen: '' },
        modo: '',
        xCenter: 0,
        yCenter: 0,
        WIDTH: 0,
        latLng: { lat: 0, lng: 0 },
        zoom: 0,
        HEIGHT: 0,
        BBOX: '',
        radio: 0,
        reduce: -1,
        opacity: { opacity: 0, layerName: '' },
        screenShot: '',
        clicPoint: { x: 0, y: 0 },
        paginasCsv:{inicio:0, fin:0, total:0, serNombre:''},
        screenShotBookMark: '',
        geoJson: {}
    };
    private ACTUALIZAR_CAPA = '[Visor] capa a actualizar';
    private ADD_LAYER = '[DATA TABLE] Add Layer';
    private REMOVE_LAYER = '[DATA TABLE] Remove Layer';
    private ACTUALIZAR_BOUNDS = '[DATA TABLE] Actualizar  bounds';
    private AGREGAR_SELECCION = '[DATA TABLE] Agregar seleccion';
    private BORRAR_SELECCION = '[DATA TABLE] Borrar seleccion';
    private RESIZE_VISOR = '[DATA TABLE] Resize Visor';
    private LOCATE_FEATURE = '[DATA TABLE] locate feature';
    private OPACITY_LAYER = '[Visor] Cambiar transparencia';
    private SET_CLICK_POINT = '[Localizar Orden] SetClicKPoint';
    private SET_DISTANCIA_PROFILE = '[Visor - WidgetProfile] Calcular y asignar distancia';
    private SET_MODE = '[Visor] Cambiar modo reporte';
    private SET_IMG = '[Visor] Asignar Imagen (Screen Shot)';
    private SET_VISOR = '[Visor] Set visor';
    private ADD_LOCAL_DATATABLE: string = '[Visor] Añadir un datatable local';
    private CHANGE_PAGE_CSV_SERVICE: string = '[Visor] Cambiar pagina servicio Csv';
    private SET_IMG_BOOKMARK = '[Visor] Asignar Imagen (Screen Shot)';
    private CHANGE_PAGE_CSV_SERVICE_INFO: string = '[Visor] Actualizar información páginas csv';
    private SET_GEOJSON: string = '[Visor] Asignar GeoJSON';



    private dataTable: IDataTable = {
        layers: [],
        active: false,
    };

    actualizarCapa = createAction(this.ACTUALIZAR_CAPA, props<{ idCapa: IActualizar }>());
    addLayer = createAction(this.ADD_LAYER, props<{ layer: ILayer }>());
    removerLayer = createAction(this.REMOVE_LAYER, props<{ layer: ILayer }>());
    actualizarBounds = createAction(this.ACTUALIZAR_BOUNDS, props<{ bounds: IBounds }>());
    agregarSeleccion = createAction(this.AGREGAR_SELECCION, props<{ feature: ISeleccion }>());
    borrarSeleccion = createAction(this.BORRAR_SELECCION, props<{ id: string }>());
    resizeVisor = createAction(this.RESIZE_VISOR, props<{ reduce: number }>());
    locateFeature = createAction(this.LOCATE_FEATURE, props<{ locate: ILocate }>());
    opacityLayer = createAction(this.OPACITY_LAYER, props<{ opacity: IOpacity }>());
    setClicKPoint = createAction(this.SET_CLICK_POINT, props<{ x: number; y: number }>());
    setDistanciaProfile = createAction(this.SET_DISTANCIA_PROFILE, props<{ distanciaProfile: number }>());
    setMode = createAction(this.SET_MODE, props<{ modo: string }>());
    setImage = createAction(this.SET_IMG, props<{ screenShot: string }>());
    setVisor = createAction(this.SET_VISOR, props<{ visor: IVisor }>());
    addLocalDataTable = createAction(this.ADD_LOCAL_DATATABLE, props<{ layer: ILayer, feature: any }>());
    changePageCsvService = createAction(this.CHANGE_PAGE_CSV_SERVICE, props<{ plus: boolean, service: string }>());
    setImageBookMark = createAction(this.SET_IMG_BOOKMARK, props<{ screenShotBookMark: string }>());
    changePageCsvServiceInfo = createAction(this.CHANGE_PAGE_CSV_SERVICE_INFO, props<{ inicio: number, fin: number, total:number, serNombre:string }>());
    setGeoJson = createAction(this.SET_GEOJSON, props<{ geoJSON: any }>());

    ReducerEventosVisor = createReducer(
        this.estado,
        on(this.actualizarCapa, (state, { idCapa }) => ({
            ...state,
            operacion: 'actualizarCapa',
            capaActualizar: { ...idCapa },
        })),

        on(this.addLayer, (state, { layer }) => {
            let tablas = { ...state.dataTable };
            if (!state.dataTable.layers.some((capa) => layer.id == capa.id)) {
                tablas = { layers: [...state.dataTable.layers, { ...layer }], active: true };
            }
            return { ...state, dataTable: tablas, operacion: 'addLayer' };
        }),
        on(this.setImageBookMark, (state, { screenShotBookMark }) => ({
            ...state,
            screenShotBookMark,
        })), on(this.changePageCsvService, (state, { plus, service }) => ({
            ...state,
            pageCsvService: { plus, service },
        })),
        on(this.changePageCsvServiceInfo, (state, { inicio, fin, serNombre, total }) => ({
            ...state,
            paginasCsv: { inicio, fin, serNombre, total },
        })),
        on(this.setGeoJson, (state, { geoJSON }) => ({
            ...state,
            geoJson: { ...geoJSON }
        })),
        on(this.removerLayer, (state, { layer }) => {
            const layersAct = state.dataTable.layers.filter((layerFilter) => {
                if (layer.id !== layerFilter.id) {
                    return true;
                }
                return false;
            });
            return {
                ...state,
                dataTable: { layers: layersAct, active: this.dataTable.active },
                operacion: 'removerLayer',
            };
        }),

        on(this.actualizarBounds, (state, { bounds }) => ({
            ...state,
            ...bounds,
            operacion: 'actualizarBounds',
        })),

        on(this.agregarSeleccion, (state, { feature }) => ({
            ...state,
            seleccion: [...state.seleccion, { ...feature }],
            operacion: 'agregarSeleccion',
        })),
        on(this.borrarSeleccion, (state, { id }) => {
            const features = state.seleccion.filter((feature) => feature.llave !== id);
            return { ...state, seleccion: [...features], operacion: 'borrarSeleccion' };
        }),
        on(this.resizeVisor, (state, { reduce }) => ({
            ...state,
            reduce,
            operacion: 'resizeVisor',
        })),
        on(this.locateFeature, (state, { locate }) => ({
            ...state,
            locate: { ...locate },
            operacion: 'locateFeature',
        })),
        on(this.opacityLayer, (state, { opacity }) => ({
            ...state,
            opacity,
            operacion: 'opacityLayer',
        })),
        on(this.setClicKPoint, (state, { x, y }) => ({
            ...state,
            clicPoint: { x, y },
        })),
        on(this.setDistanciaProfile, (state, { distanciaProfile }) => ({
            ...state,
            distanciaProfile,
        })),
        on(this.setImage, (state, { screenShot }) => ({
            ...state,
            screenShot,
        })),
        on(this.setMode, (state, { modo }) => ({
            ...state,
            modo,
        })),
        on(this.setVisor, (state, { visor }) => ({
            ...visor,
        }))
    );

    reducerEventosVisor = (state = this.estado, action: Action): IVisor => this.ReducerEventosVisor(state, action);
}

export class VisorSelector {
    getVisorState = createSelector(getVisorState, (state: IVisorState) => state.visorState);
    getCapaActualizada = createSelector(this.getVisorState, (state: IVisor) => state.capaActualizar);
    getLastLayer = createSelector(this.getVisorState, (state: IVisor) => state.dataTable.layers);
    getSeleccion = createSelector(this.getVisorState, (state: IVisor) => state.seleccion);
    getLastAction = createSelector(this.getVisorState, (state: IVisor) => state.operacion);
    getReduceSize = createSelector(this.getVisorState, (state: IVisor) => state.reduce);
    getLocateFeature = createSelector(this.getVisorState, (state: IVisor) => state.locate);
    getOpacityLayer = createSelector(this.getVisorState, (state: IVisor) => state.opacity);
    getPoint = createSelector(this.getVisorState, (state: IVisor) => state.clicPoint);
    getBBOX = createSelector(this.getVisorState, (state: IVisor) => state.BBOX);
    getDistanciaProfile = createSelector(this.getVisorState, (state: IVisor) => state.distanciaProfile);
    getMode = createSelector(this.getVisorState, (state: IVisor) => state.modo);
    getImg = createSelector(this.getVisorState, (state: IVisor) => state.screenShot);
    getClickPoint = createSelector(this.getVisorState, (state: IVisor) => state.clicPoint);
    getImgBookMark = createSelector(this.getVisorState, (state: IVisor) => state.screenShotBookMark);
    getPageCsvServiceInfo = createSelector(this.getVisorState, (state: IVisor) => state?.paginasCsv);
    getGeoJson = createSelector(this.getVisorState, (state: IVisor) => state.geoJson);
    getPageCsvService = createSelector(this.getVisorState, (state: IVisor) => state.pageCsvService);

}
