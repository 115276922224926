import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { GlobalSeguridad } from "@seguridad";
import { isEmpty } from "lodash";
import * as normalize from "normalize-object";
import { map, tap } from "rxjs/operators";
import { IHistoricalGpsRequest, IHistoricalGpsResponse } from "../../store";
import Swal from "sweetalert2";
import { environment } from "environments/environment";
import { controllers } from "chart.js";

@Injectable({
  providedIn: "root",
})
export class VisorDatosService {
  urlSigmaConsulta: string;
  urlSigmaHistoricalGps: string;
  header: any = {};
  constructor(
    private http: HttpClient,
    private seguridadGlobal: GlobalSeguridad
  ) {
    this.urlSigmaConsulta = JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion';
    this.urlSigmaHistoricalGps = JSON.parse(sessionStorage.getItem('webConfig'))['servicioGps'];
    this.header = { "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion };
  }


  getServicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))["servicioFw"] + "/FUNSigmaConsulta/ObtenerInformacion",
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion
      },
      //httpOptions
      { headers: new HttpHeaders(this.header) }
    );
  }

  geoserverQuery(url: string): Observable<any> {
    const headersT = new HttpHeaders({ Proxy: "true" });
    return this.http.get(url, { headers: headersT });
  }

  enviarRespuesta(registerNews: any): Observable<any> {
    const url = JSON.parse(sessionStorage.getItem('webConfig'))['servicioNotificacion'] + '/FUNMonitoreo/RespuestaNotificacion';
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      url,
      { ...registerNews },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  getConfigLeaflet(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    let mapId = JSON.parse(filtro)['mapa'];
    const url = JSON.parse(sessionStorage.getItem('webConfig')).proxy + JSON.parse(sessionStorage.getItem('webConfig')).obtenerMapa + `${mapId}`;
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json", 'Content-Types': this.seguridadGlobal.atributoSesion,
          'Cache-Control': 'no-cache',
        }),
      })
      // return this.http
      //   .post(
      //     this.urlSigmaConsulta,
      //     {
      //       sistema: sistema,
      //       idFuncion: idFuncion,
      //       filtro: filtro,
      //       datosPeticion: datosPeticion,
      //     },
      //     { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
      //   )
      .pipe(
        map((configLeaflet: any) => {
          let isObject = {};
          try {
            isObject = normalize(JSON.parse(configLeaflet));
          } catch (error) {
            isObject = normalize(configLeaflet);
          }

          if (configLeaflet.mapa.mapJson.widgets) {
            isObject["mapa"]["mapJson"]["widgets"] = JSON.parse(
              configLeaflet.mapa.mapJson.widgets
            );
          } else {
            isObject["mapa"]["mapJson"]["widgets"] = [];
          }

          if (configLeaflet.mapa.mapJson.mapasBase) {
            isObject["mapa"]["mapJson"]["mapasBase"] = JSON.parse(
              configLeaflet.mapa.mapJson.mapasBase
            );
          } else {
            isObject["mapa"]["mapJson"]["mapasBase"] = [];
          }


          if (configLeaflet.mapa.mapJson.mapExtent) {
            isObject["mapa"]["mapJson"]["mapExtent"] = JSON.parse(
              configLeaflet.mapa.mapJson.mapExtent
            );
          } else {
            isObject["mapa"]["mapJson"]["mapExtent"] = {};
          }



          return isObject;
        }),
        map((configLeaflet: { mapa: Array<any>; servicio: Array<any> }) => {
          if (!isEmpty(configLeaflet)) {
            return {
              map: configLeaflet?.mapa,
              service: configLeaflet?.servicio.map((x) => ({
                ...x?.capJson,
                collapsed: false,
                serCapasActivas: x?.capJson?.serCapasActivas?.map((c) => ({
                  ...c,
                  collapsed: false,
                })),
              })),
            };
          }
          return undefined;
        })
      );
  }

  getConfigForHistoricalGps(
    sistema,
    idFuncion,
    filtro,
    datosPeticion
  ): Observable<any> {
    return this.http.post(
      this.urlSigmaConsulta,
      {
        sistema: sistema,
        idFuncion: idFuncion,
        filtro: filtro,
        datosPeticion: datosPeticion,
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Content-Types": this.seguridadGlobal.atributoSesion,
        }),
      }
    );
  }

  getHistoricalGps(
    sistema: string,
    historicalGpsRequest: IHistoricalGpsRequest
  ) {
    return this.http
      .post(
        this.urlSigmaHistoricalGps,
        {
          sistema: sistema,
          parametros: JSON.stringify(historicalGpsRequest),
        },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Content-Types": this.seguridadGlobal.atributoSesion,
          }),
        }
      )
      .pipe(
        map((historicalGps: Array<IHistoricalGpsResponse>) =>
          historicalGps.filter((d: IHistoricalGpsResponse) => {
            return d.Eventos.features.length > 0;
          })
        ),
        map((historicalGps: Array<IHistoricalGpsResponse>) =>
          historicalGps.map((historical) => ({
            ...historical,
            color: this.getRandmonColor(),
          }))
        ),
        tap((historicalGps: Array<IHistoricalGpsResponse>) =>
          this.showAlertWhenNoDataFound(historicalGps)
        )
      );
  }

  getRandmonColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  private showAlertWhenNoDataFound(historicalGps: Object) {
    if (isEmpty(historicalGps)) {
      const toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });

      toast.fire({
        icon: "error",
        title: "Gps no transmitio para ese rango de fechas.",
      });
    }
  }
  servicioSigmaConsulta(sistema, idFuncion, filtro, datosPeticion): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['servicioFw'] + '/FUNSigmaConsulta/ObtenerInformacion',
      {
        sistema,
        idFuncion,
        filtro,
        datosPeticion,
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }
  servicioObtenerInformacionCache(idObjetoPizarra, filtro, datosPeticion): Observable<any> {
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))['urlServicioPizarra'] + '/FUNDatosCache/ConsultarInformacionCache',
      {
        idObjetoPizarra,
        filtro,
        datosPeticion,
      },
      // httpOptions
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Content-Types': this.seguridadGlobal.atributoSesion }) }
    );
  }

  /**
   * Obtiene los dominios para poblar los select del grid
   * @author Elisabeth Campuzano Jaramillo
   * @date 13/02/2022
   * @param find dominio a buscar
   */
  ObtenerDominiosCapas(find: string) {
    return this.http.get(JSON.parse(sessionStorage.getItem('webConfig')).obtenerDominiosCapas + find, {
      headers: new HttpHeaders({
        'Content-Types': this.seguridadGlobal.atributoSesion
      })
    });
  }

  getServicioRealizarAccion(sistema, usuario,nombreObjeto, jsonDatos, jsonDatosPeticion, accion, keyUrl, controlador): Observable<any> {
    this.seguridadGlobal.validarSesion();
    return this.http.post(
      JSON.parse(sessionStorage.getItem('webConfig'))[keyUrl] + `/${controlador}/RealizarAccion`,
      {
        sistema: sistema,
        usuario: usuario,
        nombreObjeto: nombreObjeto,
        jsonDatos: jsonDatos,
        jsonDatosPeticion: jsonDatosPeticion,
        accion: accion
      },
      { headers: new HttpHeaders({ "Content-Type": "application/json", "Content-Types": this.seguridadGlobal.atributoSesion }) }
    );
  }
}
