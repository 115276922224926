import { createAction, props } from '@ngrx/store';

const LOAD_INFO_lAYER = '[INFO_LAYER] load info Layer';
const DATA_LOADED = '[INFO_LAYER] data loaded info layer';
const SET_INFO_lAYER = '[INFO_LAYER] set info Layer';
const RESET_INFO_lAYER = '[INFO_LAYER] reset info Layer';
const FAIL_LOADING_INFO_lAYER = '[INFO_LAYER] fail loading info Layer';

export const setInfoLayer = createAction(SET_INFO_lAYER, props<{ payload: Array<any> }>());
export const loadInfoLayer = createAction(LOAD_INFO_lAYER, props<{ url: string, serviceName: string, activeLayers: Array<any> }>());
export const dataloaded = createAction(DATA_LOADED, props<{ loaded: boolean }>());
export const resetInfoLayer = createAction(RESET_INFO_lAYER);
export const failLoadingInfoLayer = createAction(FAIL_LOADING_INFO_lAYER, props<{ error: any }>());


