import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FiltroCompuestoComponent } from '@shared/components/filtro-compuesto-v2/filtro-compuesto.component';

@Component({
    selector: 'app-lista-informacion-multiple-tabs-v3',
    templateUrl: './lista-informacion-multiple-tabs-v3.component.html',
    styleUrls: ['./lista-informacion-multiple-tabs-v3.component.scss'],
})
export class ListaInformacionMultipleTabsV3Component implements OnInit, OnDestroy, OnChanges {
    @Input() rows = [];
    @Input() columns = [];
    @Input() settings: any;
    @Input() idSigmaConsultaFiltro: any;
    @Input() mostrarExportar = true;
    @Input() mostrarFiltro = true;
    @Output() cambioTabLista = new EventEmitter();
    @Output() salida = new EventEmitter();
    @Output() page = new EventEmitter();
    @Output() filter = new EventEmitter();
    @Output() filtroCompuesto = new EventEmitter();
    @Output() exportarFiltro = new EventEmitter();

    txt_filtrar = '';

    @ViewChild(DatatableComponent) table: DatatableComponent;

    @Input() tabs: any;
    @Input() tabActivo: any;

    selected: any[] = [];
    temp: any = [];
    loading: any;
    paging: any;
    title: any;
    type: any;
    size: any;
    offset: any;
    rowCount: any;
    filtro: any;
    valorFiltroCompuesto: any[] = [];
    filtroTotal: any[] = [];
    idSigmaConsultaExportar: any;
    messages = {
        emptyMessage: '<div class="empty-row">No hay datos a mostrar</div>',
    };

    constructor(private modalService: NgbModal) {
        this.temp = [...this.rows];
    }

    ngOnInit() {
        this.table.selected = [];
    }

    ngOnDestroy() {
        this.table.selected = [];
    }

    ngOnChanges() {
        this.temp = [...this.rows];
        this.loading = this.settings.loading;
        this.paging = this.settings.paging;
        this.title = this.settings.title;
        this.type = this.settings.type;
        this.size = this.settings.size;
        this.offset = this.settings.pageNumber;
        this.rowCount = this.settings.totalElements;
        this.limpiarArreglo();
    }

    filtrar(event) {
        const val = event.target.value.toLowerCase();
        if (val === '') {
            this.filtro = '';
            this.FilterFromServer();
            this.table.offset = 0;
        }
        if (this.paging === false) {
            if (val) {
                const rowsFilter = this.temp.filter(function (objeto) {
                    for (const i in objeto) {
                        const valorObjeto = String(objeto[i]);
                        const existe = valorObjeto.toLowerCase().indexOf(val) !== -1 || !val;
                        if (existe) {
                            return objeto;
                        }
                    }
                });
                // update the rows
                this.rows = rowsFilter;

                // Whenever the filter changes, always go back to the first page
                this.table.offset = 0;
            } else {
                this.rows = [...this.temp];
                // Whenever the filter changes, always go back to the first page
                this.table.offset = 0;
            }
        }
    }

    FilterFromServer() {
        const val = this.filtro.toLowerCase();
        if (this.paging === true) {
            this.filter.next(val);
        }
    }

    GetSearch(event) {
        if (this.paging === true) {
            this.FilterFromServer();
        } else {
            this.filtrar(event);
        }
    }

    limpiarArreglo() {
        if (this.table != undefined) {
            this.table.selected = [];
        }
    }

    /**
     * Abre un popup de acuerdo a un sigma consulta definido
     * @author Michael Agudelo
     * @date 2020-07-10.
     */
    AbrirPopup() {
        try {
            const modalRef = this.modalService.open(FiltroCompuestoComponent, { backdrop: 'static', size: 'lg', centered: true });
            modalRef.result.then((resultado) => {
                this.filtroCompuesto.next(resultado['filtroTotal']);
                this.valorFiltroCompuesto = resultado['registros'];
                this.filtroTotal = resultado['filtroTotal'];
            });
            const camposSet: any = {};
            camposSet['id_sigma_consulta'] = this.idSigmaConsultaFiltro;
            camposSet['valoresLista'] = this.valorFiltroCompuesto;
            modalRef.componentInstance.esPopup = true;
            modalRef.componentInstance.camposSet = camposSet;
            modalRef.componentInstance.modalRef = modalRef;
        } catch (e) {
            console.log('Error: ' + e);
        }
    }

    /**
     * @description exporta a excel los datos de la lista luego de aplicar un filtro compuesto
     * @author Michael Agudelo
     * @date 23/07/2020
     * @param
     * @returns void
     */
    ExportarExcel() {
        this.idSigmaConsultaExportar = 'EXP_' + this.idSigmaConsultaFiltro;
        const objetoExportar = { filtroTotal: this.filtroTotal, idSigmaConsultaExportar: this.idSigmaConsultaExportar };
        this.exportarFiltro.next(objetoExportar);
    }
}
