import { ShaSerDatosService } from '@shared/services/sha-ser-datos';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppStore } from './app-state-store';
import { mergeMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAPPState, IDataTable, ISummary } from './estados/estados';
import { Mensaje } from '@mensaje';

@Injectable()
export class AppEffects {
    appStore: AppStore = new AppStore();
    mensaje: Mensaje = new Mensaje('', '', '');
    @Effect()
    data$ = this.actions$.pipe(
        ofType(this.appStore.cargarObjetosControl),
        mergeMap(({ ...sigmaConsulta }) =>
            this.servicioDatos.servicioSigmaConsultaFiltro('', sigmaConsulta.id, sigmaConsulta.filtro, '').pipe(
                map((objetosControl) => {
                    let res: any[] = [];
                    if (objetosControl.respuesta === 'true') {
                        if (objetosControl?.datos?.objetosControl) {
                            res = [...objetosControl.datos.objetosControl];
                        } else if (objetosControl?.datos?.datosT && objetosControl?.datos?.datosF) {
                            const datosCompletos = [];
                            for (let index = 0; index < objetosControl?.datos?.datosT.length; index++) {
                                for (let j = 0; j < objetosControl?.datos?.datosF.length; j++) {
                                    if (objetosControl?.datos?.datosT[index].nombre === objetosControl?.datos?.datosF[j].nombre) {
                                        const element = { ...objetosControl?.datos?.datosT[index], ...objetosControl?.datos?.datosF[j] };
                                        datosCompletos.push(element);
                                        break;
                                    }
                                }
                            }
                            res = [...datosCompletos];
                        }
                    }
                    return this.appStore.asignarObjetosControl({ ruta: sigmaConsulta.modulo, objs: res });
                })
            )
        )
    );
    @Effect()
    dataSub$ = this.actions$.pipe(
        ofType(this.appStore.cargarSubsistemas),
        mergeMap(({ ...sigmaConsulta }) =>
            this.servicioDatos.servicioSigmaConsultaFiltro('', sigmaConsulta.id, sigmaConsulta.filtro, '').pipe(
                map((objetosControl) => {
                    let res: any[] = [];
                    if (objetosControl.respuesta === 'true') {
                        if (objetosControl?.datos?.subsistemas) {
                            res = [...objetosControl.datos.subsistemas];
                        } else if (objetosControl?.datos?.datosT && objetosControl?.datos?.datosF) {
                            const datosCompletos = [];
                            for (let index = 0; index < objetosControl?.datos?.datosT.length; index++) {
                                for (let j = 0; j < objetosControl?.datos?.datosF.length; j++) {
                                    if (objetosControl?.datos?.datosT[index].nombre === objetosControl?.datos?.datosF[j].nombre) {
                                        const element = { ...objetosControl?.datos?.datosT[index], ...objetosControl?.datos?.datosF[j] };
                                        datosCompletos.push(element);
                                        break;
                                    }
                                }
                            }
                            res = [...datosCompletos];
                        }
                    }
                    return this.appStore.asignarSubsistemas({ ruta: sigmaConsulta.modulo, subs: res });
                })
            )
        )
    );

    @Effect()
    dataOperation$ = this.actions$.pipe(
        ofType(this.appStore.cargarTarjetaPrincipal),
        mergeMap(({ ...sigmaConsulta }) =>
            this.servicioDatos.servicioSigmaConsultaFiltro('', sigmaConsulta.id, sigmaConsulta.filtro, '').pipe(
                map((datos) => {
                    let res: any[] = [];
                    if (datos.respuesta === 'true') {
                        res = [...datos.datos.datos];
                    }
                    return this.appStore.asignarOperaciones({ datos: res, consulta: sigmaConsulta });
                })
            )
        )
    );

    @Effect()
    configSummary$ = this.actions$.pipe(
        ofType(this.appStore.initSummary),
        mergeMap(({ ...config }) =>
            this.servicioDatos.servicioObtenerInformacionCache(config.consulta.id, config.consulta.filtro, {}).pipe(
                map((datos) => {
                    let res: ISummary[] = [];
                    if (datos.respuesta === 'true') {
                        res = [...datos.datos];
                    }
                    return this.appStore.loadSummary({ id: config.id, conf: res });
                })
            )
        )
    );

    @Effect()
    dataTableConfig$ = this.actions$.pipe(
        ofType(this.appStore.loadDataTable),
        mergeMap(({ ...config }) =>
            this.servicioDatos.servicioObtenerInformacionCache(config.consulta.id, config.consulta.filtro, '').pipe(
                map((datos) => {
                    let res: IDataTable[] = [];
                    if (datos.respuesta === 'true') {
                        res = [...datos.datos];
                    }
                    return this.appStore.initDataContainer({
                        ...res[0],
                        id: config.id,
                        cacheID: config.consulta.id,
                        filtroCache: config.consulta.filtro,
                    });
                })
            )
        )
    );
    @Effect()
    dataTable$ = this.actions$.pipe(
        ofType(this.appStore.initDataTableRows),
        mergeMap(({ ...config }) => {
            this.storeApp.dispatch(this.appStore.resetDataContainer({ idTable: config.id }));
            const tempFilter = JSON.parse(config.consulta.filtro);
            tempFilter.pagingMode = true;
            // this.mensaje.retornarMensajeFuncion("loading", "Cargando", "Por favor espere...", "", null, null, null);
            return this.servicioDatos.servicioSigmaConsultaFiltro('', config.consulta.id, JSON.stringify(tempFilter), '').pipe(
                map((datos) => {
                    let res = [];
                    if (datos.respuesta === 'true') {
                        res = [...datos.datos.datos];
                    } else {
                        res.push({ count: 0 });
                    }

                    return { ...config, count: res[0].count };
                })
            );
        }),
        mergeMap(({ ...config }) => {
            const tempFilter = JSON.parse(config.consulta.filtro);
            tempFilter.pagingMode = false;
            return this.servicioDatos.servicioSigmaConsultaFiltro('', config.consulta.id, JSON.stringify(tempFilter), '').pipe(
                map((datos) => {
                    let res: any[] = [];
                    if (datos.respuesta === 'true') {
                        res = [...datos.datos.datos];
                    }
                    // this.mensaje.cerrarMensajeCarga();
                    return this.appStore.loadDataTableRows({
                        data: res,
                        count: config.count,
                        dataContainer: config.id,
                        query: { ...config.consulta },
                    });
                })
            );
        })
    );
    constructor(private actions$: Actions, private storeApp: Store<IAPPState>, private servicioDatos: ShaSerDatosService) {}
}
