import * as fromActions from '../actions';
import { ActionReducer } from '@ngrx/store';

export function restoreStoreReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === fromActions.restoreVisorState.type) {
            const visor = { ...action['visor'], widget: { ...action['visor'].widget, active: false, toggled: false } };
            const newState = { ...state, visor };
            return reducer(newState, action);
        }
        return reducer(state, action);
    };
}
