<button id="button-close" type="button" (click)="cerrarModal()" class="btn btn-raised btn-warning mr-1">
    <i class="ft-x"></i>
</button>
<div class="card">
    <div class="card-body">
        <div class="row" *ngIf="!this.resultadoConfigFiltroCompuesto && this.arrCampos.length === 0">
            <div class="col-12 d-flex justify-content-center text-center">
                <p class="">Consultando campos <em class="fas fa-redo-alt fa-x5 fa-spin"></em></p>
            </div>
        </div>
        <div *ngIf="this.resultadoConfigFiltroCompuesto && this.arrCampos.length > 0">
            <div>
                <span>
                    <h4 class="form-section"><i class="ft-filter"></i>FILTRO</h4>
                </span>
            </div>
            <br />
            <div class="row">
                <div class="form-group col-md-4 mb-1">
                    <label>Campos</label>
                    <ng-select bindLabel="text" bindValue="id" [items]="arrCampos" (change)="validarTipoCampo($event)"
                        name="campos" id="campos" [(ngModel)]="campo">
                    </ng-select>
                </div>
                <div class="form-group col-md-4 mb-1">
                    <label>Operador</label>
                    <ng-select bindLabel="text" bindValue="id" [items]="arrOperadores" name="operador" id="operador"
                        [(ngModel)]="operador">
                    </ng-select>
                </div>
                <div *ngIf="(tipoCampo == 'text' || tipoCampo == 'string') && esCombo == true"
                    class="form-group col-md-4 mb-1">
                    <label>Valor</label>
                    <ng-select bindLabel="text" bindValue="id" [items]="arrDominio" name="valor" id="valor"
                        [(ngModel)]="valor"> </ng-select>
                </div>
                <div *ngIf="(tipoCampo == 'text' || tipoCampo == 'string') && esCombo == false"
                    class="form-group col-md-4 mb-1">
                    <label for="valor">Valor</label>
                    <input type="text" id="valor" class="form-control" name="valor" placeholder=""
                        [(ngModel)]="valor" />
                </div>
                <div *ngIf="(tipoCampo == 'num' || tipoCampo == 'numeric'|| tipoCampo == 'number')"
                    class="form-group col-md-4 mb-1">
                    <label for="valor">Valor</label>
                    <input type="number" id="valor" class="form-control" name="valor" placeholder=""
                        [(ngModel)]="valor" />
                </div>
                <div *ngIf="tipoCampo == 'date'" class="form-group col-md-4 mb-1">
                    <label for="valor">Valor</label>
                    <input type="date" id="valor" class="form-control" name="valor" placeholder=""
                        [(ngModel)]="valor" />
                </div>
                <div *ngIf="tipoCampo == 'time'" class="form-group col-md-4 mb-1">
                    <label for="valor">Valor</label>
                    <input type="time" id="valor" class="form-control" name="valor" placeholder=""
                        [(ngModel)]="valor" />
                </div>
                <div *ngIf="tipoCampo == 'boolean'" class="form-group col-md-4 mb-1">
                    <label>Valor</label>
                    <ng-select bindLabel="text" bindValue="id" [items]="arrBoolean" name="valor" id="valor"
                        [(ngModel)]="valor"> </ng-select>
                </div>
            </div>
            <br />
            <div class="row justify-content-center">
                <button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="agregarFiltro()"
                    [disabled]="!(this.campo !== '' && this.operador !== '' && this.valor !== '')">
                    <i>Agregar</i>
                </button>
                <br />
                <button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="eliminarFiltro()">
                    <i>Eliminar</i>
                </button>
                <br />
                <button class="col-md-2 btn btn-raised btn-primary mr-1" (click)="enviarFiltro()">
                    <i>Enviar Filtro</i>
                </button>
            </div>
            <br />
            <div class="form-group">
                <app-lista-informacion-v2 [rows]="rows" [columns]="columns" [settings]="configuracionLista"
                    (salida)="objetoListaSeleccionado($event)">
                </app-lista-informacion-v2>
            </div>
            <br />
        </div>
    </div>
</div>