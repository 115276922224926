<ngu-carousel
    #myCarousel
    class="px-1 py-0"
    [inputs]="carouselConfig"
    [dataSource]="coleccionSubsistemas$ | async"
    *ngIf="subsistemaActual$ | async as subsistemaActual"
>
    <div *nguCarouselDef="let item" class="item">
        <div class="tile">
            <div
                class="card-body"
                (click)="cambioSubsistema(item)"
                [className]="item.json.sub_gradie"
                [ngClass]="{
          bordeTarjetaSeleccionado:
            subsistemaActual.json.sub_nombre.toUpperCase() ==
            item.json.sub_nombre.toUpperCase()
        }"
            >
                <div class="card-block pt-2 pb-0">
                    <div class="media">
                        <div class="media-body white text-left">
                            <h3 class="font-large-1 mb-0" [ngClass]="{ oculto: mostrarCantidad(item) }">{{ item.cantidad || 0 }}</h3>
                            <span>{{ item.json.sub_alias }}</span>
                        </div>
                        <div class="media-right white text-right">
                            <img [src]="item.json.sub_icono.valor" *ngIf="item.json.sub_icono.tipo === 'imagen'; else icono" />
                            <ng-template #icono>
                                <em [class]="item.json.sub_icono.valor"></em>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button NguCarouselNext class="color-sub rightRs" [hidden]="!((coleccionSubsistemas$| async)?.length > carouselConfig?.grid?.xs)">&gt;</button>
    <button NguCarouselPrev class="color-sub leftRs" [hidden]="!((coleccionSubsistemas$| async)?.length > carouselConfig?.grid?.xs)">&lt;</button>
</ngu-carousel>
