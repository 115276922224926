import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { loadInfoLayer, setInfoLayer, failLoadingInfoLayer } from '../actions';
import { VisorDatosService } from '../../shared/services/visor-datos.service';
import { of } from 'rxjs';

@Injectable()
export class InfoLayerEffects {
    @Effect()
    data$ = this.actions$.pipe(
        ofType(loadInfoLayer),
        mergeMap((payload: { url: string; serviceName: string; activeLayers: any[] }) =>
            this.visorDatosService.geoserverQuery(payload.url).pipe(
                map((data) => {
                    const features = data?.features?.map((feature: { id: string; properties: object }) => {
                        const layerId = feature?.id.split('.')[0];
                        const layer = payload.activeLayers.filter((x) => x.id === layerId);
                        let prop: any;
                        layer[0]?.info?.campos.forEach((x) => {
                            if (feature?.properties.hasOwnProperty(x.id)) {
                                if (prop === undefined) {
                                    prop = {};
                                }
                                prop[x.alias == "" ? x.id : x.alias] = feature?.properties[x.id];
                            }
                        });
                        return {
                            serviceName: payload.serviceName,
                            layerId,
                            properties: prop !== undefined && prop != "" ? prop : feature?.properties,
                            buttons: layer[0]?.info?.botones,
                        };
                    });
                    if (features.length == 0) {
                        features[0] = {
                            serviceName: '',
                            layerId: 'Atención!',
                            properties: { Mensaje: 'En este punto no hay información!' },
                            buttons: [],
                        };
                    }
                    return setInfoLayer({ payload: features });
                }),
                catchError((error: any) => of(failLoadingInfoLayer({ error: error?.error?.text })))
            )
        )
    );

    constructor(private actions$: Actions, private visorDatosService: VisorDatosService) { }
}
