import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import {
    IBuscar,
    IModule,
    IObjetoControl,
    IRutas,
    ISigmaConsulta,
    IOperation,
    IAPPState,
    ISubsistema,
    IOperations,
    ISummary,
    IConfSummary,
    IList,
    IDataTable,
    IConfTable,
    IActiveTab,
    IFilterData,
    IFilterOperators,
} from './estados/estados';
import * as filterConstats from './estados/filterConstants';
interface Filter {
    condition: any;
    id: any;
    operator1: string;
    operator2: string;
    type: string;
    value1: string;
    value2: string;
}

export class AppStore {
    private estado: any = {};
    private estadoOperadores: IFilterData = {
        operators: {
            date: [],
            numeric: [],
            text: [],
            time: [],
        },
        action: '',
    };
    private rutas: IRutas = { rutaActual: '', rutaAnterior: '' };
    private activeModule = '';
    private operaciones: IOperations = { operations: [], loading: false, query: { id: '', filtro: '', modulo: '' } };
    private tablaDatos: IDataTable = {
        class: '',
        columns: [],
        height: '',
        llave: '',
        pagination: true,
        rowSelection: '',
        width: '',
        data: [],
        rowSelected: [],
        id: '',
        query: { id: '', filtro: '', modulo: '' },
        count: 0,
        domain: [],
        validatePending: false,
        cacheID: '',
        filtroCache: '',
        model: {},
        blockedFields: [],
    };
    private CARGAR_APP = '[APP_STORE] Cargar aplicación';
    private INIT_SUMMARY = '[APP_STORE] Inicializar resumen';
    private LOAD_SUMMARY = '[APP_STORE] cargar resumen';
    private CARGAR_TARJETAPRINCIPAL = '[APP_STORE] Cargando tarjeta principal';
    private ASIGNAR_OPERACIONES = '[APP_STORE] Asignar Tarjeta Principal';
    private ASIGNAR_OPERACION = '[APP_STORE] Seleccionar elemento tarjeta principal';
    private ABRIR_MODULO = '[APP_STORE] Cargar módulo';
    private SET_MODULE = '[APP_STORE] Asignar módulo';
    private ASIGNAR_CONSULTA_OBJETOS_CONTROL = '[APP_STORE] Asignar Sigma Consulta Objetos Control';
    private CARGAR_OBJETOSCONTROL = '[APP_STORE] Cargando Objetos Control';
    private ASIGNAR_OBJETOSCONTROL = '[APP_STORE] Asignar Objetos Control';
    private ASIGNAR_OBJETOCONTROL = '[APP_STORE] Seleccionar Objeto Control';
    private ASIGNAR_CONSULTA_SUBSISTEMAS = '[APP_STORE] Asignar Sigma Consulta Subsistemas';
    private CARGAR_SUBSISTEMAS = '[APP_STORE] Cargando Subsistemas';
    private ASIGNAR_SUBSISTEMAS = '[APP_STORE] Asignar Subsistemas';
    private ASIGNAR_SUBSISTEMA = '[APP_STORE] Seleccionar Subsistema';
    private INIT_DATA_CONTAINER = '[APP_STORE] Registrar contenedor de datos';
    private SET_DATA = '[APP_STORE] Asignar datos al contenedor';
    private SET_DATA_LOAD_STATE = '[APP_STORE] Asignar estado contenedor carga de datos';
    private CARGAR_LISTA = '[APP_STORE] Cargar datos Lista';
    private ASIGNAR_CONFIG_LISTA = '[APP_STORE] Asignar config Lista';
    private ASIGNAR_DATOS_LISTA = '[APP_STORE] Asignar datos Lista';
    private MODULE_SELECT_ITEM_LIST = '[APP_STORE] Seleccion item de la lista';
    private SELECTED_ROWS_TABLE = '[APP_STORE] Filas seleccionadas tabla datos';
    private EDIT_ROW_TABLE = '[APP_STORE] Edicion registro tabla datos';
    private SELECTED_ROW_TABLE = '[APP_STORE] Seleccionar fila tabla datos';
    private UNSELECTED_ROW_TABLE = '[APP_STORE] Deseleccionar fila tabla datos';
    private ACTIVATE_ROW_TABLE = '[APP_STORE] Activar Fila seleccionada tabla datos';
    private UPDATE_FILTER_TABLE = '[APP_STORE] Actualizar filtro tabla';
    private INIT_DATA_TABLE = '[APP_STORE] inicializar tabla de datos';
    private INIT_DATA_TABLE_ROWS = '[APP_STORE] Conteo de datos';
    private LOAD_DATA_TABLE_CONFIG = '[APP_STORE] Cargar datos tabla';
    private DOUBLE_CLICK_EVENT_TABLE = '[APP_STORE] double click event table';
    private EXPORT_FILE_TABLE_CSV = '[APP_STORE] export file table csv';
    private LOAD_TABLE_DATA = '[APP_STORE] Asignar datos a la tabla';
    private REGISTER_DOMAIN = '[APP_STORE] Cargar Dominio';
    private RESTORE_SELECTED_ROW = '[APP_STORE] Restaurar Fila seleccionada';
    private CONFIRM_EDITION = '[APP_STORE] Confirmar la edicion';
    private BLOCK_ROW = '[APP_STORE] Bloquear fila para la edición';
    private RESET_DATA_CONTAINER = '[APP_STORE] Reiniciar el data container';
    private SAVE_FILTER_MODEL = '[APP_STORE] Guardar Filtro';
    private ADD_ROW_TABLE = '[APP_STORE_ADD_ROW_TABLE] Adición registro tabla datos';
    private SAVE_RECORDS = '[APP_STORE_SAVE_RECORDS] Guardar registro';
    private EDIT_RECORDS = '[APP_STORE_EDIT_RECORDS] Editar registro';
    private DELETE_RECORDS = '[APP_STORE_DELETE_RECORDS] Eliminar registro';
    private ADD_ROW_FORM = '[APP_STORE_ADD_ROW_FORM] Adición registro formulario';
    private RESET_SELECTED_ROWS = '[APP_STORE_RESET_SELECTED_ROWS] Restaura las filas seleccionadas';
    private SET_TAB = '[APP_STORE_SET_TAB] Asignar pestaña';
    private DESELECT_ROWS = '[APP_STORE_DESELECT_ROWS] Deseleccionar filas tabla datos';
    private ERASE_SELECTED_ROWS = '[APP_STORE_DESELECT_ROWS] Borrar selección tabla datos';
    private HIGLIGHT_ROWS = '[APP_STORE_HIGLIGHT_ROWS] Resaltar filas tabla datos';
    private RESET_ROWS_TABLE = '[APP_STORE_RESET_ROWS_TABLE] Restaura las filas de la tabla';
    private SEND_RECORDS = '[APP_STORE_SEND_RECORDS] Enviar registros';
    private UPDATE_COUNT = '[APP_STORE_UPDATE_COUNT] Refrescar conteo subsistemas';
    private LOAD_DATA = '[APP_STORE_LOAD_DATA] Acción para recargar la tabla de datos desde el componente';
    private SET_REQUIRED_FIELD = '[APP_STORE_SET_REQUIRED_FIELD] Mostrar que un campo es requerido';
    private SET_BLOCK_FIELD = '[APP_STORE_SET_BLOCK_FIELD] Bloquear o desbloquear un campo';
    private RESET_FIELD_VALUE = '[APP_STORE_RESET_FIELD_VALUE] Reinicia el valor de una celda';
    private SET_FILTER = '[APP_STORE_SET_FILTER] Agregar o modificar el filtro de una columna';
    private SET_FILTER_OPERATORS = '[APP_STORE_SET_FILTER_OPERATORS] Agregar operadores para los filtros de las tablas';

    cargarApp = createAction(this.CARGAR_APP);
    cargarModulo = createAction(this.ABRIR_MODULO, props<IModule>());
    setModule = createAction(this.SET_MODULE, props<{ module: string }>());
    cargarObjetosControl = createAction(this.CARGAR_OBJETOSCONTROL, props<ISigmaConsulta>());
    cargarTarjetaPrincipal = createAction(this.CARGAR_TARJETAPRINCIPAL, props<ISigmaConsulta>());
    asignarOperaciones = createAction(this.ASIGNAR_OPERACIONES, props<{ datos: IOperation[]; consulta: ISigmaConsulta }>());
    asignarOperacion = createAction(this.ASIGNAR_OPERACION, props<{ ruta: string; codigo: string }>());
    asignarSigmaConsultaObjetoControl = createAction(this.ASIGNAR_CONSULTA_OBJETOS_CONTROL, props<ISigmaConsulta>());
    asignarObjetoControl = createAction(this.ASIGNAR_OBJETOCONTROL, props<{ ruta: string; codigo: string }>());
    asignarObjetosControl = createAction(this.ASIGNAR_OBJETOSCONTROL, props<{ ruta: string; objs: IObjetoControl[] }>());
    asignarSigmaConsultaSubsistemas = createAction(this.ASIGNAR_CONSULTA_SUBSISTEMAS, props<ISigmaConsulta>());
    cargarSubsistemas = createAction(this.CARGAR_SUBSISTEMAS, props<ISigmaConsulta>());
    asignarSubsistemas = createAction(this.ASIGNAR_SUBSISTEMAS, props<{ ruta: string; subs: ISubsistema[] }>());
    asignarSubsistema = createAction(this.ASIGNAR_SUBSISTEMA, props<{ ruta: string; subCodigo: string }>());
    initDataContainer = createAction(this.INIT_DATA_CONTAINER, props<IList | IDataTable>());
    setData = createAction(this.SET_DATA, props<{ containerID: string; data: any[] }>());
    setDataLoadState = createAction(this.SET_DATA_LOAD_STATE, props<{ containerID: string; loaded: boolean }>());
    cargarLista = createAction(this.CARGAR_LISTA, props<IList>());
    asignarConfigLista = createAction(this.ASIGNAR_CONFIG_LISTA, props<IList>());
    asignarDatosLista = createAction(this.ASIGNAR_DATOS_LISTA, props<{ ruta: string; idLista: string; datos: any[] }>());
    moduleSelectItemList = createAction(this.MODULE_SELECT_ITEM_LIST, props<{ idLista: string; datos: any[] }>());
    initSummary = createAction(this.INIT_SUMMARY, props<{ id: string; consulta: ISigmaConsulta }>());
    loadSummary = createAction(this.LOAD_SUMMARY, props<IConfSummary>());
    selectedRowsTable = createAction(this.SELECTED_ROWS_TABLE, props<{ selectedRows: string }>());
    selectedRowTable = createAction(this.SELECTED_ROW_TABLE, props<{ selectedRow: any; idTable: string; pk: string }>());
    unSelectedRowTable = createAction(this.UNSELECTED_ROW_TABLE, props<{ selectedRow: any; idTable: string; pk: string }>());
    activateRowTable = createAction(this.ACTIVATE_ROW_TABLE, props<{ selectedRow: any; idTable: string; pk: string }>());
    updatedFilterTable = createAction(this.UPDATE_FILTER_TABLE, props<{ filter: string; id: string; columna: string; serNombre: string }>());
    initDataTable = createAction(this.INIT_DATA_TABLE, props<{ id: string; consulta: ISigmaConsulta }>());
    loadDataTable = createAction(this.LOAD_DATA_TABLE_CONFIG, props<{ id: string; consulta: ISigmaConsulta }>());
    doubleClickedEventTable = createAction(this.DOUBLE_CLICK_EVENT_TABLE, props<{ row: any }>());
    exportFileTableCsv = createAction(this.EXPORT_FILE_TABLE_CSV, props<{ exportCsv: any }>());
    initDataTableRows = createAction(this.INIT_DATA_TABLE_ROWS, props<{ id: string; consulta: ISigmaConsulta }>());
    loadDataTableRows = createAction(
        this.LOAD_TABLE_DATA,
        props<{
            data: any[];
            dataContainer: string;
            query: ISigmaConsulta;
            count: number;
        }>()
    );
    editTableRow = createAction(this.EDIT_ROW_TABLE, props<{ row: any; idTable: string; column: string; table?: boolean }>());
    addTableRow = createAction(this.ADD_ROW_TABLE, props<{ row: any; idTable: string; model: any }>());
    registerDomain = createAction(
        this.REGISTER_DOMAIN,
        props<{ idDomain: string; idTable: string; loading?: boolean; values: { id: string; text: string }[] }>()
    );
    restoreSelectedRow = createAction(this.RESTORE_SELECTED_ROW);
    confirmEdition = createAction(this.CONFIRM_EDITION, props<{ idTable: string }>());
    blockRow = createAction(this.BLOCK_ROW, props<{ idTable: string; key; block: boolean }>());
    resetDataContainer = createAction(this.RESET_DATA_CONTAINER, props<{ idTable: string }>());
    saveFilterModel = createAction(this.SAVE_FILTER_MODEL, props<{ idTable: string; filter: any }>());
    saveRecords = createAction(this.SAVE_RECORDS, props<{ idTable: string }>());
    editRecords = createAction(this.EDIT_RECORDS, props<{ idTable: string }>());
    deleteRecords = createAction(this.DELETE_RECORDS, props<{ idTable: string }>());
    sendRecords = createAction(this.SEND_RECORDS, props<{ idTable: string }>());
    updateCount = createAction(this.UPDATE_COUNT, props<{ idTable: string }>());
    loadData = createAction(this.LOAD_DATA, props<{ idTable: string }>());
    addRowForm = createAction(this.ADD_ROW_FORM, props<{ idTable: string; row: any }>());
    resetSelectedRows = createAction(this.RESET_SELECTED_ROWS, props<IBuscar>());
    setActiveTab = createAction(this.SET_TAB, props<{ module: string; tab: IActiveTab }>());
    deselectRows = createAction(this.DESELECT_ROWS, props<{ idTable: string }>());
    resetRowsTable = createAction(this.RESET_ROWS_TABLE, props<{ idTable: string }>());
    higlightRows = createAction(this.HIGLIGHT_ROWS, props<{ idTable: string }>());
    eraseSelectedRows = createAction(this.ERASE_SELECTED_ROWS, props<{ idTable: string }>());
    setRequiredField = createAction(this.SET_REQUIRED_FIELD, props<{ idTable: string; field: string; required: boolean }>());
    setBlockField = createAction(this.SET_BLOCK_FIELD, props<{ idTable: string; row: string; column: string; block: boolean; key: string }>());
    resetFieldValue = createAction(this.RESET_FIELD_VALUE, props<{ idTable: string; row: string; column: string; key: string }>());
    setFilterForField = createAction(this.SET_FILTER, props<{ filter: Filter; idTable: string; colId: string }>());
    setFilterOperators = createAction(this.SET_FILTER_OPERATORS, props<{ operators: IFilterOperators }>());

    reducerEventosModulos = createReducer(
        this.estado,
        on(this.cargarApp, (state) => ({ ...this.estado })),
        on(this.setModule, (state, module) => ({ ...state, activeModule: module.module })),
        on(this.cargarModulo, (state, modulo: IModule) => {
            const mod = { ...state };
            mod[modulo.ruta] = { ...modulo };
            return { ...mod };
        }),
        on(this.asignarOperacion, (state, op) => {
            const mod = { ...state };
            const module: IModule = { ...mod[op.ruta] };
            module.operacion = op.codigo;
            mod[op.ruta] = module;
            return { ...mod };
        }),
        on(this.asignarObjetosControl, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...mod[obj.ruta] };
            const objs: IObjetoControl[] = [...obj.objs];
            if (module.objetosControl !== undefined) {
                for (let i = 0; i < module.objetosControl.length; i++) {
                    for (let j = 0; j < obj.objs.length; j++) {
                        if (module.objetosControl[i].obc_codigo === obj.objs[j].obc_codigo) {
                            objs[j] = {
                                ...objs[j],
                                subsistemas: module.objetosControl[i].subsistemas,
                                subsistema: module.objetosControl[i].subsistema,
                            };
                        }
                    }
                }
            }
            module.objetosControl = [...objs];
            if (module.objetoControl === '') {
                module.objetoControl = module.objetosControl[0].obc_codigo;
            }
            mod[obj.ruta] = module;
            return { ...mod };
        }),
        on(this.asignarSigmaConsultaObjetoControl, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...mod[obj.modulo] };
            module.consultaObjetosControl = { ...obj };
            mod[obj.modulo] = module;
            return { ...mod };
        }),
        on(this.asignarObjetoControl, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...mod[obj.ruta] };
            module.objetoControl = obj.codigo;
            mod[obj.ruta] = module;
            return { ...mod };
        }),
        on(this.asignarSigmaConsultaSubsistemas, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...mod[obj.modulo] };
            module.consultaSubsistemas = { ...obj };
            module.loaded = false;
            mod[obj.modulo] = module;
            return { ...mod };
        }),
        on(this.asignarSubsistemas, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...state[obj.ruta] };
            module.action = 'asignarSubsistemas';
            module.objetosControl = module.objetosControl.map((objeto) =>
                objeto.obc_codigo === module.objetoControl
                    ? {
                          ...objeto,
                          subsistemas: obj.subs.map((subsistem) => {
                              const res = { ...subsistem };
                              objeto?.subsistemas?.forEach((element) => {
                                  if (element.sub_codigo === subsistem.sub_codigo && element.summaries !== undefined) {
                                      res.summaries = [...element.summaries];
                                      return true;
                                  }
                              });
                              return res;
                          }),
                          subsistema:
                              (objeto.subsistema === undefined || objeto.subsistema.length === 0) && obj.subs.length > 0
                                  ? obj.subs[0].sub_codigo
                                  : objeto.subsistema,
                      }
                    : objeto
            );
            module.loaded = true;
            mod[obj.ruta] = module;
            return mod;
        }),
        on(this.asignarSubsistema, (state, obj) => {
            const mod = { ...state };
            const module: IModule = { ...state[obj.ruta] };
            module.action = 'asignarSubsistema';
            module.objetosControl = module.objetosControl.map((objeto) =>
                objeto.obc_codigo === module.objetoControl ? { ...objeto, subsistema: obj.subCodigo } : objeto
            );
            mod[obj.ruta] = module;
            return mod;
        }),
        on(this.cargarLista, (state, datos) => ({ ...this.addModuleList(state, datos) })),
        on(this.setData, (state, datos) => ({ ...this.setDataSetList(state, datos) })),
        on(this.setDataLoadState, (state, load) => ({ ...this.setLoadData(state, load) })),
        on(this.moduleSelectItemList, (state, datos) => {
            const mod = { ...state };
            const module: IModule = { ...mod[mod.activeModule] };
            module.dataContainers = module.dataContainers.map((lista) =>
                lista.id === datos.idLista ? { ...lista, selected: [...datos.datos] } : lista
            );
            mod[mod.activeModule] = module;
            return { ...mod };
        }),
        on(this.loadSummary, (state, data) => {
            const mod = { ...state };
            const module: IModule = { ...mod[mod.activeModule] };
            module.objetosControl = module.objetosControl.map((obj) =>
                obj.obc_codigo === module.objetoControl
                    ? {
                          ...obj,
                          subsistemas: obj.subsistemas.map((sub) => {
                              const subRes = { ...sub };
                              if (
                                  subRes.sub_codigo === obj.subsistema &&
                                  subRes.summaries &&
                                  subRes?.summaries?.findIndex((sum) => sum?.id === data.id) === -1
                              ) {
                                  subRes.summaries = [...subRes.summaries, { ...data }];
                              } else if (subRes.sub_codigo === obj.subsistema && !sub.summaries) {
                                  subRes.summaries = [{ ...data }];
                              }
                              return subRes;
                          }),
                      }
                    : obj
            );
            mod[mod.activeModule] = module;
            return { ...mod };
        }),
        on(this.initDataContainer, (state, datos) => ({ ...this.addModuleDataContainer(state, datos) })),
        on(this.loadDataTableRows, (state, { data: _data, dataContainer, query, count }) =>
            this.setDataSet(state, { containerID: dataContainer, data: _data, query, count })
        ),
        on(this.selectedRowTable, (state, { selectedRow, idTable, pk }) => this.selectedRows(state, { selectedRow, idTable, pk }, false)),
        on(this.unSelectedRowTable, (state, { selectedRow, idTable, pk }) =>
            this.selectedRows(state, { selectedRow, idTable, pk }, false, 'unSelectedRows')
        ),
        on(this.activateRowTable, (state, { selectedRow, idTable, pk }) => this.selectedRows(state, { selectedRow, idTable, pk }, true)),
        on(this.editTableRow, (state, { row, idTable, column, table }) => this.editRow(state, { row, idTable, column, table })),
        on(this.registerDomain, (state, { idDomain, idTable, loading, values }) => this.updateDomain(state, { idDomain, idTable, loading, values })),
        on(this.restoreSelectedRow, (state) => this.setActionSelectedRow(state)),
        on(this.confirmEdition, (state, { idTable }) => this.confirmEditionRow(state, idTable)),
        on(this.blockRow, (state, { idTable, key, block }) => this.blockEditionRow(state, idTable, key, block)),
        on(this.resetDataContainer, (state, { idTable }) => this.resetContainer(state, idTable)),
        on(this.saveFilterModel, (state, { idTable, filter }) => this.saveContainerFilter(state, idTable, filter)),
        on(this.addTableRow, (state, { row, idTable, model }) => this.addRow(state, { row, idTable, model })),
        on(this.saveRecords, (state, { idTable }) => this.saveActionRecords(state, idTable)),
        on(this.editRecords, (state, { idTable }) => this.editActionRecords(state, idTable)),
        on(this.deleteRecords, (state, { idTable }) => this.deleteActionRecords(state, idTable)),
        on(this.sendRecords, (state, { idTable }) => this.sendActionRecords(state, idTable)),
        on(this.updateCount, (state, { idTable }) => this.updateActionCount(state, idTable)),
        on(this.loadData, (state, { idTable }) => this.loadActionRecords(state, idTable)),
        on(this.addRowForm, (state, { idTable, row }) => this.addRowToFrom(state, { idTable, row })),
        on(this.resetSelectedRows, (state, { codigo, ruta }) => this.resetRows(state, codigo, 'resetSelectedRows')),
        on(this.setActiveTab, (state, op) => {
            const mod = { ...state };
            const module: IModule = { ...mod[op.module] };
            module.activeTab = op.tab;
            module.action = 'setActiveTab';
            mod[op.module] = module;
            return { ...mod };
        }),
        on(this.deselectRows, (state, { idTable }) => this.deselectDataTableRows(state, idTable)),
        on(this.resetRowsTable, (state, { idTable }) => this.resetRows(state, idTable, 'resetRowsTable')),
        on(this.higlightRows, (state, { idTable }) => this.higlightDatatableRows(state, idTable)),
        on(this.eraseSelectedRows, (state, { idTable }) => this.resetRows(state, idTable, 'eraseSelectedRows')),
        on(this.setRequiredField, (state, { idTable, field, required }) => this.setRequiredDatatableField(state, idTable, field, required)),
        on(this.setBlockField, (state, { idTable, column, row, block, key }) => this.setBlockDatatableField(state, idTable, column, row, block, key)),
        on(this.resetFieldValue, (state, { idTable, column, row, key }) => this.resetDatatableFieldValue(state, idTable, column, row, key)),
        on(this.setFilterForField, (state, { filter, idTable, colId }) => this.setFilterDatatableField(state, filter, idTable, colId))
    );

    reducerEventosRuta = createReducer(
        this.rutas,
        on(this.cargarApp, (state) => ({
            ...state,
        }))
    );
    reducerEventosFiltro = createReducer(
        this.estadoOperadores,
        on(this.setFilterOperators, (state, { operators }) => this.setDataFilterOperators(state, operators))
    );

    reducerEventosOperacion = createReducer(
        this.operaciones,
        on(this.asignarOperaciones, (state, obj) => ({ ...state, loading: false, operations: [...obj.datos], query: obj.consulta })),
        on(this.cargarTarjetaPrincipal, (state) => ({ ...state, loading: true }))
    );
    reducerEventosModule = (state = this.estado, action: Action): IModule[] => this.reducerEventosModulos(state, action);
    reducerEventosRutas = (state = this.rutas, action: Action): IRutas => this.reducerEventosRuta(state, action);
    reducerEventosOperaciones = (state = this.operaciones, action: Action): IOperations => this.reducerEventosOperacion(state, action);
    private addSubsistemList(state, data) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.objetosControl = module.objetosControl.map((obj) =>
            obj.obc_codigo === module.objetoControl
                ? {
                      ...obj,
                      subsistemas: obj.subsistemas.map((sub) => {
                          const subRes = { ...sub };
                          if (
                              subRes.sub_codigo === obj.subsistema &&
                              subRes.dataContainers &&
                              subRes?.dataContainers?.findIndex((sum) => sum?.id === data.id) === -1
                          ) {
                              subRes.dataContainers = [...subRes.dataContainers, { ...data }];
                          } else if (subRes.sub_codigo === obj.subsistema && !sub.dataContainers) {
                              subRes.dataContainers = [{ ...data }];
                          }
                          return subRes;
                      }),
                  }
                : obj
        );
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private addModuleDataContainer(state, data: IList | IDataTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        if (
            module.dataContainers === undefined ||
            (module.dataContainers !== undefined && module.dataContainers.findIndex((lista) => lista.id === data.id) === -1)
        ) {
            if (module.dataContainers.length === 0) {
                module.dataContainers = [{ ...data }];
            } else {
                module.dataContainers = [...module.dataContainers, { ...data }];
            }
            module.activeDataTable = data.id;
        }

        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setActionSelectedRow(state) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'selectedRowTable';
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private confirmEditionRow(state, idTable: string) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'confirmEdition';
        module.dataContainers = module.dataContainers.map((container) =>
            container.id === idTable ? { ...container, validatePending: false } : container
        );
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private blockEditionRow(state, idTable: string, key: string, block: boolean) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'blockEditionRow';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === idTable) {
                const keys = container.llave.split(';');
                const dataNew = container.data.map((row) => {
                    const ids = this.rowIds(keys, row);
                    if (ids === key) {
                        const newRow = JSON.parse(JSON.stringify(row));
                        newRow['blockRow_'] = true;
                        return newRow;
                    }
                    return row;
                });
                return { ...container, data: dataNew, validatePending: false };
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private resetContainer(state, idTable: string) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'resetDataContainer';
        module.dataContainers = module.dataContainers.map((container) =>
            container.id === idTable ? { ...container, validatePending: false } : container
        );
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private saveContainerFilter(state, idTable: string, filter: any) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'saveFilterModel';
        module.dataContainers = module.dataContainers.map((container) => (container.id === idTable ? { ...container, filter } : container));
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private addModuleList(state, data: IList) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        if (
            module.dataContainers === undefined ||
            (module.dataContainers !== undefined && module.dataContainers.findIndex((lista) => lista.id === data.id) === -1)
        ) {
            if (module.dataContainers.length === 0) {
                module.dataContainers = [{ ...data }];
            } else {
                module.dataContainers = [...module.dataContainers, { ...data }];
            }
        }
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setDataSetList(state, data: { containerID: string; data: any[] }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.dataContainers = module.dataContainers.map((container) =>
            container.id === data.containerID ? { ...container, data: data.data, loaded: true } : container
        );
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setDataSet(state, data: { containerID: string; data: any[]; query: ISigmaConsulta; count: number }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'loadDataTableRows';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === data.containerID) {
                const keys = container.llave.split(';');
                if (container.rowSelected !== undefined && container.rowSelected.length > 0) {
                    const lastSelected = container.rowSelected[container.rowSelected.length - 1];
                    const dataTemp = data.data.map((row) => {
                        const ids = this.rowIds(keys, row);
                        if (ids === lastSelected) {
                            return { ...row, showRow: true };
                        }
                        return row;
                    });
                    return { ...container, data: [...dataTemp], query: { ...data.query }, timestamp: Date.now(), loaded: true, count: data.count };
                } else {
                    return { ...container, data: [...data.data], query: { ...data.query }, timestamp: Date.now(), loaded: true, count: data.count };
                }
            } else {
                return container;
            }
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private selectedRows(state, data: { selectedRow: any; idTable: string; pk: string }, activate: boolean, action = 'selectedRowTable') {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = action;

        /* if (!activate) {
            this.resetRows(state, data.idTable, 'cleanSelection');
        } */
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === data.idTable) {
                const _container = JSON.parse(JSON.stringify(container));
                const dataUpdated = this.findRowToShow(_container, { row: data.selectedRow, pk: data.pk }, activate);
                return { ..._container, data: dataUpdated, validatePending: _container.rowSelected.length > 0 };
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private editRow(state, _data: { row: any; idTable: string; column: string; table: boolean }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        if (_data.table === undefined) {
            module.action = 'editContainerRow';
        } else {
            module.action = 'editTableRow';
        }
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === _data.idTable) {
                return { ...container, data: this.findColumnToEdit(container, { ..._data, pk: container.llave }), validatePending: true };
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private updateDomain(state, domain: { idDomain; idTable; loading; values }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'registerDomain';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === domain.idTable) {
                const containerDomain = { ...container };
                if (!containerDomain.domain) {
                    containerDomain['domain'] = {};
                }
                const _domain = { ...containerDomain.domain };
                let domainObject = {};
                if (_domain[domain.idDomain] === undefined || _domain[domain.idDomain] === null) {
                    _domain[domain.idDomain] = domainObject;
                } else {
                    domainObject = { ..._domain[domain.idDomain] };
                }
                domainObject['values'] = JSON.stringify(domain.values);
                domainObject['loading'] = domain?.loading !== undefined ? domain?.loading : false;
                _domain[domain.idDomain] = domainObject;
                containerDomain.domain = _domain;
                return containerDomain;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private findColumnToEdit(container, _data) {
        _data.row = JSON.parse(JSON.stringify(_data.row));
        const key: string = _data.pk;
        const keys = key.split(';');

        const dataNew = container.data.map((row) => {
            if (this.validateSearch(keys, _data, row)) {
                return this.searchRow(row, _data);
            } else if (_data.row?.id && row?.id === _data.row?.id) {
                return this.searchRow(row, _data);
            }
            const _row = { ...row };
            if (_row.lastEdited) {
                delete _row.lastEdited;
            }
            return _row;
        });
        return dataNew;
    }

    validateSearch(keys: string[], data: any, row: any): boolean {
        let valid = true;
        keys.forEach((key) => {
            if (row[key] !== data.row[key]) {
                valid = false;
            }
        });
        if (!valid && data.row?.action && row?.action && data.row?.action === row?.action) {
            valid = true;
        }
        return valid;
    }

    searchRow(row, _data) {
        let editedColumn = [];
        if (row.editColumns !== undefined) {
            editedColumn = [...row.editColumns];
            const index = row.editColumns.indexOf(_data.column);
            if (index > -1) {
                editedColumn.splice(index, 1);
            }
            editedColumn.push(_data.column);
            const indexValidate = editedColumn.indexOf('isValid');
            if (indexValidate > -1) {
                editedColumn.splice(indexValidate, 1);
            }
        } else {
            editedColumn.push(_data.column);
        }
        return { ..._data.row, editColumns: editedColumn, lastEdited: true };
    }

    private findRowToShow(container, _data: { row: any; pk: string }, activate: boolean) {
        let index = -1;
        const keys = _data.pk.split(';');
        const ids = this.rowIds(keys, _data.row);
        if (container.rowSelected === undefined) {
            container['rowSelected'] = [];
            container.rowSelected.push(ids);
        } else {
            index = container.rowSelected.indexOf(ids);
            if (index > -1) {
                if (container.rowSelected[0] !== ids) {
                    container.rowSelected.splice(index, 1);
                }
            }
            if (index === -1 || activate) {
                container.rowSelected.push(ids);
            }
        }
        const dataNew = container.data.map((row) => {
            const newRow = { ...row };
            const rowIds = this.rowIds(keys, row);
            if (rowIds === ids && (index === -1 || activate)) {
                newRow['showRow'] = true;
            } else if (!activate && container.rowSelected.length > 0 && container.rowSelected[container.rowSelected.length - 1] === rowIds) {
                newRow.showRow = true;
            } else {
                delete newRow.showRow;
            }
            return newRow;
        });
        return dataNew;
    }

    rowIds(keys: string[], row: any): string {
        let ids = '';
        keys.forEach((key) => {
            if (row[key] !== undefined && row[key] !== '') {
                if (ids === '') {
                    ids = row[key];
                } else {
                    ids += ';' + row[key];
                }
            } else if (row?.action === 'add') {
                ids = 'add';
            }
        });
        return ids;
    }

    private setLoadData(state, loaded: { containerID: string; loaded: boolean }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.dataContainers = module.dataContainers.map((container) =>
            container.id === loaded.containerID ? { ...container, loaded: loaded.loaded } : container
        );
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private addRow(state, _data: { row: any; idTable: string; model: any }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'addTableRow';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === _data.idTable) {
                const newContainer = { ...container };
                newContainer.model = { ..._data.model };
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private saveActionRecords(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'saveRecords';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private editActionRecords(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'editRecords';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private deleteActionRecords(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'deleteRecords';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private sendActionRecords(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'sendRecords';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private updateActionCount(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'updateCount';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private loadActionRecords(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'loadData';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private addRowToFrom(state, _data: { idTable: string; row: any }) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'addRowToForm';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === _data.idTable) {
                const containerData = [...container.data];
                containerData.splice(0, 0, { ..._data.row });
                return { ...container, data: containerData, validatePending: true };
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setRequiredDatatableField(state: any, codigo: string, field: string, required: boolean): any {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'setRequiredField';
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === codigo) {
                const newContainer = { ...container };
                newContainer.rowSelected = [];
                if (newContainer?.data?.length > 0) {
                    const _newContainer = { ...container };
                    _newContainer.rowSelected = [];
                    if (_newContainer?.data?.length > 0) {
                        const columns = JSON.parse(JSON.stringify(_newContainer.columns));

                        _newContainer.columns = columns.map((column: any) => {
                            const newColumn = { ...column };
                            if (column.id === field) {
                                const text = newColumn.header[0].text;
                                if (required) {
                                    if (text.split('')[text.length - 1] !== '*') {
                                        newColumn.header[0].text = newColumn.header[0].text + '*';
                                    }
                                } else {
                                    if (text.split('')[text.length - 1] === '*') {
                                        newColumn.header[0].text = text.substring(0, text.length - 1);
                                    }
                                }
                            }
                            return newColumn;
                        });
                    }
                    return _newContainer;
                }
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setBlockDatatableField(state: any, codigo: string, colId: string, rowId: string, blocked: boolean, key: string): any {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'setBlockField';
        module.dataContainers = module.dataContainers.map((container: IDataTable) => {
            if (container.id === codigo) {
                const newContainer = JSON.parse(JSON.stringify(container));
                newContainer.rowSelected = [];
                if (newContainer.blockedFields === undefined) {
                    newContainer.blockedFields = [];
                }
                let included = false;
                newContainer.blockedFields = newContainer.blockedFields.map((_field) => {
                    const fieldAux = { ..._field };
                    if (fieldAux.rowId === rowId && fieldAux.colId === colId && fieldAux.key === key) {
                        fieldAux.blocked = blocked;
                        included = true;
                    }
                    return fieldAux;
                });
                if (!included) {
                    newContainer.blockedFields.push({ colId, rowId, blocked, key });
                }
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private resetDatatableFieldValue(state: any, codigo: string, colId: string, rowId: string, key: string): any {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'resetField';
        module.dataContainers = module.dataContainers.map((container: IDataTable) => {
            if (container.id === codigo) {
                const newContainer = JSON.parse(JSON.stringify(container));
                newContainer.lastResetField = {
                    colId,
                    rowId,
                    key,
                };
                newContainer?.data?.forEach((element: any) => {
                    if (element[key] === rowId) {
                        const jsonKey = this.getJsonKey(element);
                        try {
                            element[jsonKey][colId] = '';
                            // element[jsonKey][colId] = "";
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private getJsonKey(json) {
        const keys = Object.keys(json);
        let key = '';
        keys.forEach((k: string) => {
            if (k.includes('json')) {
                key = k;
                return key;
            }
        });
        return key;
    }
    private setFilterDatatableField(state: any, filter: Filter, codigo: string, colId: string): any {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'setFilterField';
        module.dataContainers = module.dataContainers.map((container: IDataTable) => {
            if (container.id === codigo) {
                const newContainer = JSON.parse(JSON.stringify(container));
                newContainer.rowSelected = [];

                newContainer.columns = newContainer.columns.map((column: any) => {
                    const columnAux = { ...column };
                    if (column.id === colId) {
                        columnAux.filter = filter;
                    }
                    return columnAux;
                });
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }
    private setDataFilterOperators(state: any, operators: IFilterOperators): any {
        const newState = { ...state, operators };
        newState.action = filterConstats.filterAddOperators;
        return { ...newState };
    }

    private resetRows(state, codigo, action) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = action;
        module.dataContainers = module.dataContainers.map((container) => {
            if (container.id === codigo && container?.rowSelected?.length > 0) {
                const newContainer = { ...container };
                newContainer.rowSelected = [];
                if (newContainer?.data?.length > 0) {
                    const newData = [...newContainer.data];
                    for (const index in newData) {
                        if (newData[index]?.showRow !== null && newData[index]?.showRow) {
                            const newRow = { ...newData[index] };
                            delete newRow.showRow;
                            newData[index] = newRow;
                        }
                    }
                    newContainer.data = newData;
                }
                return newContainer;
            }
            return container;
        });
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private deselectDataTableRows(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'deselectRows';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }

    private higlightDatatableRows(state, idTable) {
        const mod = { ...state };
        const module: IModule = { ...mod[mod.activeModule] };
        module.action = 'higlightRows';
        module.containerId = idTable;
        mod[mod.activeModule] = module;
        return { ...mod };
    }
}
// eslint-disable-next-line max-classes-per-file
export class AppSelector {
    getAction = createSelector(
        (state) => state['modulos'][state['modulos'].activeModule],
        (state) => ({ action: state?.action, containerId: state?.containerId })
    );

    private rowIds(keys: string[], row: any): string {
        let ids = '';
        keys.forEach((key) => {
            if (row[key] !== undefined && row[key] !== '') {
                if (ids === '') {
                    ids = row[key];
                } else {
                    ids += ';' + row[key];
                }
            } else if (row?.action === 'add') {
                ids = 'add';
            }
        });
        return ids;
    }

    private editedModelValues(key, row, model) {
        for (const column of Object.keys(row)) {
            if (column === key) {
                model[key] = row[key];
                break;
            } else if (row[column].constructor === Object) {
                const newObject = this.editedJsonModelValues(model[column], row[column], key);
                if (Object.keys(newObject).length > 0) {
                    if (model[column] === undefined) {
                        model[column] = {};
                    }
                    model[column] = newObject;
                }
            }
        }
    }

    private editedJsonModelValues(model: any, json: any, key: string): any {
        if (model === undefined) {
            model = {};
        }
        for (const column of Object.keys(json)) {
            if (column === key) {
                model[column] = json[column];
                break;
            } else if (json[column].constructor === Object) {
                const newObject = this.editedJsonModelValues(model[column], json[column], key);
                if (Object.keys(newObject).length > 0) {
                    if (model[column] === undefined) {
                        model[column] = {};
                    }
                    model[column] = newObject;
                }
            }
        }
        return model;
    }

    getOperacion = () =>
        createSelector(
            (state, _props) => ({ modulo: state?.modulos[state.modulos.activeModule], loading: state?.operations?.loading }),
            (state) => ({ operation: state?.modulo?.operacion, loading: state?.loading })
        );
    getOperations = () =>
        createSelector(
            (state) => state,
            (state: IAPPState) => ({ operations: state?.operations?.operations, loading: state?.operations?.loading })
        );
    getModule = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state: IModule) => {
                if (state !== undefined) {
                    return { ...state };
                } else {
                    return undefined;
                }
            }
        );
    getContainer = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state) => ({ ...state.contenedor })
        );
    getSigmaConsultaObjControl = () =>
        createSelector(
            (state, _props) => state.modulos[_props.ruta],
            (state: IModule) => {
                if (state !== undefined) {
                    return { ...state?.consultaObjetosControl };
                }
                return undefined;
            }
        );
    getObjetoControl = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state: IModule) => ({ ...state?.objetosControl.filter((objeto) => objeto.obc_codigo === state.objetoControl)[0] })
        );
    getObjetosControl = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state) => {
                if (state !== undefined) {
                    return [...state?.objetosControl];
                }
                return [];
            }
        );
    getSigmaConsultaSubsistemas = () =>
        createSelector(
            (state, _props) => state.modulos[_props.ruta],
            (state: IModule) => {
                if (state !== undefined) {
                    return { ...state.consultaSubsistemas };
                }
                return undefined;
            }
        );
    getSubsistemas = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state) => {
                const objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo === state.objetoControl)[0];
                if (objControl !== undefined && objControl.subsistemas !== undefined) {
                    return [...objControl?.subsistemas];
                }
                return [];
            }
        );
    getSubsistema = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.ruta],
            (state) => {
                const objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo === state.objetoControl)[0];
                if (objControl !== undefined && objControl.subsistemas !== undefined) {
                    const subsistema = objControl.subsistemas.findIndex((subs) => subs.sub_codigo === objControl.subsistema);
                    return { data: { ...objControl.subsistemas[subsistema] }, action: state.action, loaded: state.loaded };
                }
                return undefined;
            }
        );
    getModuleItem = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined && container?.selected?.length > 0) {
                        return container.selected[0];
                    } else if (container !== undefined && container.data) {
                        const row = container.data.find((_row) => _row.showRow);
                        if (row !== undefined) {
                            return JSON.parse(JSON.stringify(row));
                        }
                    }
                }
                return {};
            }
        );
    getDataSet = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined && container?.data !== undefined && container?.loaded !== undefined) {
                        return { data: [...container.data], loaded: container.loaded };
                    }
                }
                return { data: [], loaded: false };
            }
        );
    getConfigQueryContainer = () =>
        createSelector(
            (state, _props) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const query: ISigmaConsulta = { filtro: '', id: '', modulo: '' };
                const containers: IList[] = state?.dataContainers;
                if (containers !== undefined && containers.length > 0) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        return { ...container.configQuery };
                    }
                }
                return query;
            }
        );
    getDataQueryContainer = () =>
        createSelector(
            (state, _props) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const query: ISigmaConsulta = { filtro: '', id: '', modulo: '' };
                const containers: IList[] = state?.dataContainers;
                if (containers !== undefined && containers.length > 0) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        return { ...container.dataQuery };
                    }
                }
                return query;
            }
        );
    getQueryContainer = () =>
        createSelector(
            (state, _props) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const query: ISigmaConsulta = { filtro: '', id: '', modulo: '' };
                const containers: IDataTable[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        return { ...container.query };
                    }
                }
                return query;
            }
        );
    getLoadedState = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        return container.loaded;
                    }
                }
                return false;
            }
        );
    getSummary = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[state.modulos.activeModule],
            (state, _props) => {
                const objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo === state.objetoControl)[0];
                if (objControl !== undefined && objControl.subsistemas !== undefined) {
                    const subsistema = objControl.subsistemas.findIndex((subs) => subs.sub_codigo === objControl.subsistema);
                    if (subsistema >= 0) {
                        const summary = objControl.subsistemas[subsistema]?.summaries?.findIndex((_summary) => _summary.id === _props.codigo);
                        if (summary !== undefined && summary >= 0) {
                            return [...objControl.subsistemas[subsistema].summaries[summary].conf];
                        }
                    }
                    return [];
                }
            }
        );
    getTableDataRows = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, param: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === param.codigo);
                    if (container !== undefined && container?.data?.length > 0) {
                        return {
                            ts: container.timestamp,
                            data: [...container.data],
                            query: { ...container.query },
                            count: container.count,
                            action: state.action,
                        };
                    } else if (container !== undefined) {
                        return { ts: container.timestamp, data: [], query: { ...container.query }, count: 0, action: state.action };
                    }
                }
                return {};
            }
        );
    getDataTableConfig = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, _props) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        return { ...container, action: state.action };
                    }
                }
                return {};
            }
        );
    getFilterConfigState = () =>
        createSelector(
            (state: any, _props: any) => state['filterData'],
            (state: IFilterData, _props: any): IFilterData => state
            // return { action: state.action, operators: state.operators};
        );
    getRowSelected = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, _props) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === _props.codigo);
                    if (container !== undefined) {
                        const keys = container.llave.split(';');
                        if (container.data && container.rowSelected && container.rowSelected.length > 0) {
                            const row = container.data.find(
                                (_row) => this.rowIds(keys, _row) === container.rowSelected[container.rowSelected.length - 1]
                            );
                            if (row !== undefined) {
                                return { action: state.action, data: JSON.parse(JSON.stringify(row)), id: _props.codigo };
                            }
                        } else {
                            return { action: state.action, data: {}, id: _props.codigo };
                        }
                    }
                }
                return { action: state.action, data: {}, id: _props.codigo };
            }
        );

    getRowsSelected = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.data) {
                        const keys = container.llave.split(';');
                        const rows: any[] = container.data.filter((row) => {
                            const ids = this.rowIds(keys, row);
                            if (container.rowSelected && container.rowSelected.indexOf(ids) > -1) {
                                return true;
                            }
                            return false;
                        });
                        if (rows !== undefined) {
                            return { action: state.action, data: JSON.parse(JSON.stringify(rows)), idTable: _props.codigo };
                        }
                    }
                }
                return { action: state.action, data: [], idTable: _props.codigo };
            }
        );
    getRowsSelectedKeys = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.rowSelected) {
                        return container.rowSelected;
                    }
                }
                return [];
            }
        );
    isEditTable = () =>
        createSelector(
            (state: any) => state['modulos'][state['modulos'].activeModule],
            (state: any, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.data) {
                        const editRow = container.data.find((row) => {
                            if (row.editColumns !== undefined && row.editColumns.length > 0) {
                                return true;
                            }
                            return false;
                        });
                        if (editRow !== undefined) {
                            return editRow.isValid;
                        }
                    }
                }
                return false;
            }
        );
    getLastEditedRow = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.data) {
                        const editRow = container.data.find((row) => {
                            if (row.lastEdited !== undefined && row.lastEdited) {
                                return true;
                            }
                            return false;
                        });
                        if (editRow !== undefined) {
                            return { action: state.action, row: JSON.parse(JSON.stringify(editRow)) };
                        }
                    }
                }
                return {};
            }
        );
    getEditedRows = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.data) {
                        const editedRows = container.data
                            .filter((row) => {
                                if (row.editColumns !== undefined && row?.action !== 'add') {
                                    return true;
                                }
                                return false;
                            })
                            .map((row) => {
                                const editValues = {};
                                const keys = container.llave.split(';');
                                keys.forEach((key) => {
                                    if (key !== 'isValid') {
                                        this.editedModelValues(key, row, editValues);
                                    }
                                });
                                row.editColumns.forEach((element) => {
                                    if (element !== 'isValid') {
                                        this.editedModelValues(element, row, editValues);
                                    }
                                });
                                return { llave: container.llave, editValues, row };
                            });
                        return editedRows;
                    }
                }
                return [];
            }
        );
    getDomain = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.ruta);
                    if (container !== undefined && container.domain && container.domain[_props.codigo]) {
                        return {
                            action: state.action,
                            data: JSON.parse(container.domain[_props.codigo]?.values),
                            loading: container.domain[_props.codigo]?.loading,
                        };
                    } else {
                        return { action: state.action, data: [], loading: true };
                    }
                }
                return { action: state.action, data: [], loading: false };
            }
        );
    getRowsSelectedToolBar = () =>
        createSelector(
            (state) => state['modulos'][state['modulos'].activeModule],
            (state, _props: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((fcontainer) => fcontainer.id === _props.codigo);
                    if (container !== undefined && container.data) {
                        const rows: any[] = container.data.filter((row) => {
                            if (row.showRow !== undefined) {
                                return true;
                            }
                            return false;
                        });
                        if (rows !== undefined) {
                            return JSON.parse(JSON.stringify({ selected: rows }));
                        }
                    }
                }
                return { selected: [] };
            }
        );
    getContainerModel = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, param: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === param.codigo);
                    if (container !== undefined && container?.model && Object.keys(container.model).length > 0) {
                        return { model: { ...container.model }, action: state.action };
                    } else if (container !== undefined) {
                        return undefined;
                    }
                }
                return {};
            }
        );
    getCreateRow = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, param: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === param.codigo);
                    if (container !== undefined && container?.data?.length > 0 && Object.keys(container?.data[0]).length > 0) {
                        return { data: { ...container.data[0] }, action: state.action };
                    } else if (container !== undefined) {
                        return undefined;
                    }
                }
                return {};
            }
        );

    getActiveTab = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.codigo],
            (state: IModule) => {
                if (state !== undefined) {
                    return { tab: { ...state?.activeTab }, action: state.action };
                } else {
                    return undefined;
                }
            }
        );
    getActiveDataTable = () =>
        createSelector(
            (state, _props: IBuscar) => state.modulos[_props.codigo],
            (state: IModule) => {
                if (state !== undefined) {
                    state.dataContainers.find((_container) => _container.id === state.activeDataTable);
                    return { tab: { ...state?.activeTab }, action: state.action };
                } else {
                    return undefined;
                }
            }
        );
    getContainerFilter = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, param: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === param.codigo);
                    if (container !== undefined && container?.filter !== undefined && Object.keys(container?.filter).length > 0) {
                        return { filter: { ...container?.filter }, action: state.action };
                    }
                }
                return { filter: undefined, action: state.action };
            }
        );

    getAddedRow = () =>
        createSelector(
            (state, _props: IBuscar) => state['modulos'][state['modulos'].activeModule],
            (state, param: IBuscar) => {
                const containers: any[] = state?.dataContainers;
                if (containers !== undefined) {
                    const container = containers.find((_container) => _container.id === param.codigo);
                    if (container !== undefined && container?.data?.length > 0) {
                        const addedRow = container.data.find((row) => row['action'] === 'add');
                        if (addedRow !== undefined) {
                            return { row: { ...addedRow } };
                        }
                    }
                }
                return undefined;
            }
        );
    getActiveModule = () =>
        createSelector(
            (state) => state,
            (state: any) => ({ activeModule: state['modulos'][state['modulos'].activeModule] })
        );
    getCurrentSubsystem = () =>
        createSelector(
            (state: any) => state['modulos'][state['modulos'].activeModule],
            (state) => {
                const objControl: IObjetoControl = state?.objetosControl.filter((objeto) => objeto.obc_codigo === state.objetoControl)[0];
                if (objControl !== undefined && objControl.subsistemas !== undefined) {
                    const subsistema = objControl.subsistemas.findIndex((subs) => subs.sub_codigo === objControl.subsistema);
                    return {
                        subsystem: objControl.subsistemas[subsistema]?.json?.sub_nombre,
                        tabs: objControl.subsistemas[subsistema]?.json?.sub_tabs,
                        action: state.action,
                        objControl: objControl?.json?.obc_nombre,
                        activeModule: state.ruta,
                    };
                }
                return undefined;
            }
        );
}
