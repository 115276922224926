import { IFilterOperators } from "./estados";

export const filterAddOperators = "filterAddOperators";

export const operators:IFilterOperators = {
    "text": [
        {
            "text": "Igual",
            "id": "="
        },
        {
            "text": "Contiene",
            "id": "%@%"
        },
        {
            "text": "Empieza",
            "id": "@%"
        },
        {
            "text": "Termina",
            "id": "%@"
        }
    ],
    "date": [
        {
            "text": "Igual",
            "id": "="
        },
        {
            "text": "Menor que",
            "id": "<"
        },
        {
            "text": "Menor o igual que",
            "id": "<="
        },
        {
            "text": "Mayor que",
            "id": ">"
        },
        {
            "text": "Mayor o igual que",
            "id": ">="
        }
    ],
    "numeric": [
        {
            "text": "Igual",
            "id": "="
        },
        {
            "text": "Menor que",
            "id": "<"
        },
        {
            "text": "Menor o igual que",
            "id": "<="
        },
        {
            "text": "Mayor que",
            "id": ">"
        },
        {
            "text": "Mayor o igual que",
            "id": ">="
        }
    ],
    "time": [
        {
            "text": "Igual",
            "id": "="
        },
        {
            "text": "Menor que",
            "id": "<"
        },
        {
            "text": "Menor o igual que",
            "id": "<="
        },
        {
            "text": "Mayor que",
            "id": ">"
        },
        {
            "text": "Mayor o igual que",
            "id": ">="
        }
    ]
};