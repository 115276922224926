import { ActionReducerMap } from '@ngrx/store';

import { reducerMenu } from './widget.reducer';
import { reducerConfigMap } from './config-leaflet.reducer';
import { reducerGalleryMap } from './gallery-map.reducer';
import { reducerInfoLayer } from './info-layer.reducer';
import { reducerHistoricalGps } from './historical-gps.reducer';
import { reducerNotification } from './notification.reducer';
import { DrawMapStore } from '@leafletStore/class/draw-map';
import { VisorStore } from '@leafletStore/class/visor-leaflet';

export * from './widget.reducer';
export * from './config-leaflet.reducer';
export * from './gallery-map.reducer';
export * from './info-layer.reducer';
export * from './restore-store.reducer';
export * from './reset-store.reducer';
export * from './historical-gps.reducer';
export * from './notification.reducer';

const drawMapStore: DrawMapStore = new DrawMapStore();
const visorStore: VisorStore = new VisorStore();
export const AppReducers: ActionReducerMap<any> = {
    widget: reducerMenu,
    configLeaflet: reducerConfigMap,
    galleryMapState: reducerGalleryMap,
    infoLayers: reducerInfoLayer,
    historicalGps: reducerHistoricalGps,
    notification: reducerNotification,
    drawMode: drawMapStore.reducerDrawMode,
    visorState: visorStore.reducerEventosVisor,
};
