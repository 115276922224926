/**
* @description popup llamado desde el contenedor funcionario desde la barra de herramientas
* @author Jhonatan David Mosquera
* @date 15/04/2020
*/
import { Component, OnInit } from '@angular/core';
import { GlobalSeguridad } from '@seguridad';
import { Mensaje } from '@mensaje';
import { ServicioPopupService } from '../servicio-popup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';

@Component({
  selector: 'app-popup-carmas',
  templateUrl: './popup-carmas.component.html',
  styleUrls: ['./popup-carmas.component.scss']
})
export class PopupCarmasComponent implements OnInit {

  mensaje: Mensaje;
  arrPlantillas: any;
  tipoCargaMasiva: any;
  filtroCargaMasiva: any;
  modelo: any;
  modalRef: any;

  constructor(private globalSeguridad: GlobalSeguridad, 
    private servicioPopupService: ServicioPopupService,
    public activeModal: NgbActiveModal,
    public utilidades: ServicioUtilidadesService) {
    this.mensaje = new Mensaje("","","");
   }

  ngOnInit() {
    this.obtenerConfigCargaMasiva();
  }

  /**
  * @description Obtiene la configuración de la carga masiva
  * @author Jhonatan David Mosquera
  * @date 15/04/2020
  * @param  
  * @returns
  */
  obtenerConfigCargaMasiva(){
    let filtro = "{'tipo':'geoambiental'}";
    let idSigmaConsulta = "CARGA_MASIVA";
    this.servicioPopupService.getServicioSigmaConsulta("", idSigmaConsulta, filtro, "").subscribe(res => {
      let respuesta = this.utilidades.RetornarStringObjeto(res.datos).datos;
      this.arrPlantillas = respuesta;
    });
  }

  /**
  * @description Actualiza la información del sistema luego de realizar la carga masiva
  * @author Jhonatan David Mosquera
  * @date 15/04/2020
  * @param 
  * @returns
  */ 
  actualizarInformacion(){
  }

  cerrarPopup(){
    this.activeModal.close();
  }
}
