import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Mensaje } from '@mensaje';
import { Store } from '@ngrx/store';
import { SettingsList } from '@settingsList';
import { AppSelector, AppStore } from '@shared/store/app-state-store';
import { IAPPState } from '@shared/store/estados/estados';
import { ShaSerDatosService } from '@shared/services/sha-ser-datos';
import { ServicioUtilidadesService } from '@shared/services/servicio-utilidades.service';

@Component({
    selector: 'app-filtro-compuesto',
    templateUrl: './filtro-compuesto.component.html',
    styleUrls: ['./filtro-compuesto.component.scss'],
})
export class FiltroCompuestoComponent implements OnInit {
    resultadosDominios: any;
    modelo: any;
    regularForm: FormGroup;
    bloqueDominios: any = true;
    mensaje: Mensaje;
    bloqueoBoton: any = true;

    arrBoolean = [
        { id: true, text: 'Si' },
        { id: false, text: 'No' },
    ];

    // variables para configuracion de popup
    camposSet: any = {};
    modalRef: any;

    // variables para la lista
    rows: any;
    columns: any;
    configuracionLista = new SettingsList();
    tempConfiguracionColumnasPopup = '[{"prop":"campo","name":"Campo"},{"prop":"operador","name":"Operador"},{"prop":"valor","name":"Valor"}]';
    coleccionDatosLista: any[] = [];
    posicionElemento: any = 0;

    resultadoConfigFiltroCompuesto: any;
    resultadoConfigFiltroCompuestoCasteado: any[];
    arrCampos: any[] = [];
    arrOperadores: any[] = [];
    operadores: any[] = [];
    arrDominio: any;
    esCombo = false;
    dominio: any;
    campo: any = '';
    operador: any = '';
    valor: any = '';
    tipoCampo: any = '';
    appStore: AppStore;
    appSelector: AppSelector;

    constructor(private servicioDatos: ShaSerDatosService, public utilidades: ServicioUtilidadesService, private store: Store<IAPPState>) {
        this.mensaje = new Mensaje('', '', '');
        this.appSelector = new AppSelector();
        this.appStore = new AppStore();
        this.resultadoConfigFiltroCompuestoCasteado = [];
    }

    ngOnInit(): void {
        // Lista Nueva
        this.configuracionLista.pageNumber = 0;
        this.columns = JSON.parse(this.tempConfiguracionColumnasPopup);
        this.rows = [];

        this.obtenerDatosFiltroCompuesto();
    }

    /**
     * Obtiene los datos de la configuración del filtro compuesto
     * @author Michael Agudelo
     * @date 2020-07-10.
     * @param
     * @returns
     */
    obtenerDatosFiltroCompuesto() {
        try {
            // Se comenta el código que se usaba antes por sí es de alguna utilidad para el desarrollo
            // let filtro = this.camposSet["id_sigma_consulta"];
            const filtro = this.camposSet['idTable'];
            const valoresLista = this.camposSet['valoresLista'];
            this.coleccionDatosLista = valoresLista;
            let cargado = false;
            this.store
                .select(this.appSelector.getDataTableConfig(), { codigo: filtro, ruta: '' })
                .takeWhile((cargar) => !cargado)
                .subscribe((datos) => {
                    if (!cargado && datos?.columns !== undefined) {
                        cargado = true;
                        this.resultadoConfigFiltroCompuesto = datos?.columns;
                        this.arrCampos = datos.columns.map((col: any) => {
                            const colAux: any = {};
                            let text = col.header[0].text;
                            colAux.id = col.id;
                            if (text.split('')[text.length - 1] === '*') {
                                text = text.substring(0, text.length - 1);
                            }
                            colAux.text = text;
                            //
                            colAux.tipo = this.transformDataFilter(col);
                            //
                            colAux.campo_json = col.json ? col.json : col.campo_json ? col.campo_json : '';
                            colAux.dominio = col.dominio ? col.dominio : col.domainId ? col.domainId : '';
                            return colAux;
                        });

                        this.resultadoConfigFiltroCompuesto.forEach((element: any) => {
                            this.transformJSONFiltroCompuesto(element);
                        });
                        this.rows = [...this.coleccionDatosLista];
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    transformDataFilter(inData): string {
        if (inData.type) {
            if (inData.type === 'mask') {
                if (inData.mask != null) {
                    return inData.mask;
                } else {
                    if (inData.filter.type) {
                        if (inData.type === 'num') {
                            return 'numeric';
                        } else {
                            return inData.filter.type;
                        }
                    } else {
                        return 'text';
                    }
                }
            } else {
                return inData.type;
            }
        } else {
            if (inData.type === 'num') {
                return 'numeric';
            } else {
                return inData.filter.type;
            }
        }
    }

    /**
     *
     * @author Luis David Marin Gallego
     */
    private transformJSONFiltroCompuesto(element: any) {
        let text = '';
        element['header'].forEach((a) => {
            if (a['text']) {
                text = a['text'];
            }
        });
        text = text.replace('*', '');
        const dominio = this.validateDomain(element);
        const campo_json = element['json'] ? element['json'] : '';
        let domainSource;
        if (dominio !== '') {
            domainSource = element['domainSource'] ? element['domainSource'] : '';
        }
        const newElement = {
            id: element['id'],
            text,
            tipo: '',
            dominio,
            domainSource,
            campo_json,
            operadores: [],
        };
        if (element['type']) {
            if (element['type'] === 'mask') {
                if (element['mask'] !== undefined && element['mask'] !== null) {
                    newElement['operadores'] = this.getOperadores(element['mask']);
                    newElement['tipo'] = this.validateType(element['mask']);
                } else {
                    if (element['filter']['type']) {
                        if (element['filter']['type'] === 'num') {
                            newElement['operadores'] = this.getOperadores('numeric');
                            newElement['tipo'] = 'numeric';
                        } else {
                            newElement['operadores'] = this.getOperadores(element['filter']['type']);
                            newElement['tipo'] = this.validateType(element['filter']['type']);
                        }
                    } else {
                        newElement['operadores'] = this.getOperadores('text');
                        newElement['tipo'] = 'text';
                    }
                }
            } else {
                if (element['type'] === 'num') {
                    newElement['operadores'] = this.getOperadores('numeric');
                    newElement['tipo'] = 'numeric';
                } else {
                    newElement['operadores'] = this.getOperadores(element['type']);
                    newElement['tipo'] = this.validateType(element['type']);
                }
            }
        } else {
            newElement['operadores'] = this.getOperadores(element['filter']['type']);
            newElement['tipo'] = this.validateType(element['filter']['type']);
        }

        this.resultadoConfigFiltroCompuestoCasteado.push(newElement);
    }

    private getOperadores(tipo: string) {
        switch (tipo) {
            case 'date':
                return [
                    {
                        id: '=',
                        text: 'Igual',
                    },
                    {
                        id: '<',
                        text: 'Menor que',
                    },
                    {
                        id: '<=',
                        text: 'Menor o igual que',
                    },
                    {
                        id: '>',
                        text: 'Mayor que',
                    },
                    {
                        id: '>=',
                        text: 'Mayor o igual que',
                    },
                ];
            case 'numeric' || 'num':
                return [
                    {
                        id: '=',
                        text: 'Igual',
                    },
                    {
                        id: '<',
                        text: 'Menor que',
                    },
                    {
                        id: '<=',
                        text: 'Menor o igual que',
                    },
                    {
                        id: '>',
                        text: 'Mayor que',
                    },
                    {
                        id: '>=',
                        text: 'Mayor o igual que',
                    },
                ];
            case 'text' || 'string':
                return [
                    {
                        id: '=',
                        text: 'Igual',
                    },
                    {
                        id: '%@%',
                        text: 'Contiene',
                    },
                    {
                        id: '@%',
                        text: 'Empieza',
                    },
                    {
                        id: '%@',
                        text: 'Termina',
                    },
                ];

            default:
                // console.warn('El tipo: ' + tipo + ' no es soportado, se tratará como texto.');
                return [
                    {
                        id: '=',
                        text: 'Igual',
                    },
                    {
                        id: '%@%',
                        text: 'Contiene',
                    },
                    {
                        id: '@%',
                        text: 'Empieza',
                    },
                    {
                        id: '%@',
                        text: 'Termina',
                    },
                ];
        }
    }

    cerrarModal() {
        this.modalRef.close();
    }

    objetoListaSeleccionado(evento) {
        try {
            const registro = evento.selected[0];
            for (let i = 0; i < this.coleccionDatosLista.length; i++) {
                if (registro === this.coleccionDatosLista[i]) {
                    this.posicionElemento = i;
                }
            }
        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    /**
     * @description Valida que el filtro a ingresar no exista
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    validarExisteFiltro(filtro: any) {
        try {
            for (const iterator of this.coleccionDatosLista) {
                if (filtro.campo === iterator['campo'] && filtro.operador === iterator['operador'] && filtro.valor === iterator['valor']) {
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.log('Error: ' + error);
        }
    }

    /**
     * @description Valida el tipo de campo de la configuración, si tiene dominio muestra el combobox; sino , la caja de texto
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    validarTipoCampo(evento: any) {
        try {
            this.dominio = '';
            let domainSource: string;
            console.log(evento);
            console.log(this.resultadoConfigFiltroCompuesto);
            if (!evento) {
                return;
            }
            for (const config of this.resultadoConfigFiltroCompuestoCasteado) {
                if (evento['id'] === config['id']) {
                    this.dominio = config['dominio'];
                    this.operadores = config['operadores'];
                    if (config['tipo'] == 'num' || config['tipo'] == 'number') {
                        config['tipo'] = 'numeric';
                    }else if (config['tipo'] == 'string') {
                        config['tipo'] = 'text';
                    }
                    this.tipoCampo = config['tipo'];
                    domainSource = config['domainSource'];
                }
            }

            if (this.dominio !== '') {
                this.store
                    .select(this.appSelector.getDomain(), { codigo: this.dominio, ruta: this.camposSet['idTable'] })
                    .take(1)
                    .subscribe((domainData) => {
                        if (domainData.data.length > 0) {
                            this.arrDominio = domainData.data;
                        } else if (domainSource !== undefined && domainSource === 'sigmaConsulta') {
                            this.store
                                .select(this.appSelector.getCurrentSubsystem())
                                .take(1)
                                .subscribe((data) => {
                                    if (data !== undefined) {
                                        const moduloID = data?.activeModule;
                                        this.store.select(this.appSelector.getOperacion(), { ruta: moduloID }).subscribe((operacion) => {
                                            if (operacion.operation !== undefined && operacion.operation !== '' && !operacion.loading) {
                                                const filterOperation = `{'operacion':'${this.utilidades.construirFiltroOperacion(
                                                    operacion.operation
                                                )}'}`;
                                                const filterDomain = this.dominio;
                                                this.servicioDatos
                                                    .servicioSigmaConsultaFiltro('', filterDomain, filterOperation, '')
                                                    .subscribe((res) => {
                                                        if (res.respuesta === 'true') {
                                                            const respuesta = res.datos.datos;
                                                            this.arrDominio = respuesta;
                                                        } else {
                                                            this.arrDominio = [];
                                                        }
                                                    });
                                            }
                                        });
                                    }
                                });
                        } else {
                            const filtro = this.dominio;
                            this.servicioDatos.servicioObtenerInformacionCache('DOMINIOS', filtro, '').subscribe((res) => {
                                if (res.respuesta === 'true') {
                                    const respuesta = res.datos;
                                    this.arrDominio = respuesta[this.dominio];
                                } else {
                                    this.arrDominio = [];
                                }
                            });
                        }
                    });
                this.esCombo = true;
            } else {
                this.esCombo = false;
            }
            this.arrOperadores = [...this.operadores];
        } catch (error) { }
    }

    /**
     * @description Agregar campos seleccionados a la lista de filtros
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    agregarFiltro() {
        if (this.campo !== '' && this.operador !== '' && this.valor !== '') {
            const objeto = { campo: this.campo, operador: this.operador, valor: this.valor };
            if (!this.validarExisteFiltro(objeto)) {
                this.coleccionDatosLista.push(objeto);
                this.configuracionLista.pageNumber = 0;
                this.rows = [...this.coleccionDatosLista];
            }
        }

        this.campo = '';
        this.operador = '';
        this.valor = '';
    }

    /**
     * @description eliminar filtro seleccionado de la lista de filtros
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    eliminarFiltro() {
        if (this.coleccionDatosLista[this.posicionElemento]?.origen === 'filtroTabla') {
            this.mensaje.retornarMensajeFuncion('error', '', 'Los filtros de la tabla solo se pueden eliminar desde la misma.', '', null, null, null);
            // this.mensaje.retornarMensaje("error", "", "Los filtros de la tabla solo se pueden eliminar desde la misma.")
        } else {
            this.coleccionDatosLista.splice(this.posicionElemento, 1);
            this.rows = [...this.coleccionDatosLista];
        }
    }

    /**
     * @description crear cadena con el filtro a enviar
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    enviarFiltro() {
        const filtroTotal = [];
        let filtro = {};
        let campo = '';
        let operador = '';
        let valor = '';
        let configCampo = '';
        let tipo = '';
        let campoJson = '';
        for (const datos of this.coleccionDatosLista) {
            campo = datos['campo'];
            operador = datos['operador'];
            valor = datos['valor'];
            configCampo = this.obtenerConfiguracionCampo(campo);
            tipo = configCampo['tipo'];
            campoJson = configCampo['campo_json'];

            filtro = { campo, operador, valor, tipo, campoJson };
            filtroTotal.push(filtro);
        }

        this.modalRef.close({ filtroTotal, registros: this.rows });
    }

    /**
     * @description obtiene el tipo de dato de un campo de la lista de filtros
     * @author Michael Agudelo
     * @date 14-07-2020
     * @param evento
     * @returns
     */
    obtenerConfiguracionCampo(campo) {
        let config = '';
        for (const configFiltro of this.resultadoConfigFiltroCompuestoCasteado) {
            if (campo === configFiltro['id']) {
                config = configFiltro;
                break;
            }
        }
        return config;
    }

    confirmarSeleccion() {
        const parametros = {
            contenedor: this,
        };
        // Muestra un modal para asegurar la accion que quiere realizar el usuario
        this.mensaje.retornarMensajeFuncion(
            'confirmacion',
            '¿Desea agregar el registro seleccionado?',
            '',
            '',
            this,
            'retornarResultado',
            parametros
        );
    }

    /**
     * Valida si la columna es un dominio y de qué tipo es
     * @author Lukas Mesa Buriticá
     * @date 2022-09-12
     * @param element objeto json con la configuración de la columna
     * @returns cadena con el valor del dominio sí cumple la condición de validación
     */
    validateDomain(element: any) {
        let value = '';
        if (
            element['domainId'] !== undefined &&
            element['domainId'] !== '' &&
            (element['domainDynamic'] === undefined || !element['domainDynamic'])
        ) {
            value = element['domainId'];
        }
        return value;
    }

    /**
     * Valida el tipo de la columna
     * @author Lukas Mesa Buriticá
     * @date 2022-09-12
     * @param element cadena con el valor del tipo de la columna
     * @returns cadena con el valor del tipo de la columna para el servicio
     */
    validateType(element: any) {
        let value = '';
        if (element !== undefined && element === 'string') {
            value = 'text';
        } else {
            value = element;
        }
        return value;
    }
}
