import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { IHistoricalGpsResponse } from '../actions/historical-gps.actions';
import { VisorDatosService } from '../../shared/services/visor-datos.service';
import { LoadHistoricalGps, LoadHistoricalGpsSuccess } from '../actions/historical-gps.actions';

@Injectable()
export class HistoricalGpsEffects {
    @Effect()
    data$ = this.actions$.pipe(
        ofType(LoadHistoricalGps),
        switchMap(({ payloadRequest }) =>
            this.visorDatosService
                .getHistoricalGps(sessionStorage.getItem("sistemaLogueado"), payloadRequest)
                .pipe(
                    map((historicalGps: Array<IHistoricalGpsResponse>) => LoadHistoricalGpsSuccess({ payloadResponse: historicalGps }))
                )
        )
    );

    constructor(private readonly actions$: Actions, private readonly visorDatosService: VisorDatosService) { }
}
