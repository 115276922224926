import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Mensaje } from '@mensaje';
import { DynamicService } from '../dynamic.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-tree',
  templateUrl: './dynamic-form-tree.component.html',
  styleUrls: ['./dynamic-form-tree.component.scss']
})
export class DynamicFormTreeComponent implements OnInit {

  fields: any[];
  forms: any = undefined;
  nodes: any[];
  useCheckbox;
  formsConfig: any[];
  message: Mensaje;
  idModelCurrent = '';
  idFormDynamicCurrent = '';
  pk = '';

  @Input() config: any;
  @Input() configService: any;
  @Input() sistemaActual: any = '';
  @Output() buttonEvent = new EventEmitter();

  constructor(private dynamicService: DynamicService
  ) { this.message = new Mensaje('', '', ''); }

  ngOnInit() {
    this.fields = [];
    this.nodes = [];
    this.dynamicService.configService = this.configService;
    this.dynamicService.dynamicFormTree = this;
    this.useCheckbox = this.config.useCheckbox;
  }


  // tslint:disable-next-line: use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.dynamicService.dynamicFormTree = this;
    if (changes.config) {
      const currentValuePk = changes.config.currentValue.pk ? changes.config.currentValue.pk : '';
      const previousValuePk = changes.config.previousValue && changes.config.previousValue.pk ? changes.config.previousValue.pk : '';
      if (currentValuePk !== previousValuePk) {
        this.pk = currentValuePk;
        this.getTreeModel(currentValuePk, changes.config.currentValue.type);
      }
      this.useCheckbox = this.config.useCheckbox;
    }
  }

  getModelInit() {
    if (this.config.pk || this.config.type) {
      this.getTreeModel(this.config.pk, this.config.type);
    }
  }

  customEvent(nameObject, action, json) {
    // this.eventAction(nameObject, action, this.pk, json, '');
    const object = { nameObject: nameObject, action: action, pk: this.pk, json: json };
    this.buttonEvent.next(object);
  }

  onUpload(e) {
    console.log(e);
  }

  getTreeModel(pk: string, type: string) {
    try {
      this.message.retornarMensajeFuncion('loading', 'Procesando tu solicitud...', '', '', null, '', null);
      const idSigmaConsulta = this.configService.idSigmaQuery;
      const filtro = '{"codigo": "' + pk + '","tipo": "' + type.toUpperCase() + '"}';
      this.dynamicService.getServicioSigmaConsulta(this.sistemaActual, idSigmaConsulta, filtro, '{}')
        .subscribe(data => {
          if (data.respuesta === 'true') {
            const tree = JSON.parse(data.datos).arbol;

            const nodes = tree[0].nodes;
            const formsConfig = tree[0].formularios[0].definitions;

            if (nodes) {
              this.nodes = nodes;
            }
            if (formsConfig) {
              this.formsConfig = formsConfig;
            }
            this.message.cerrarMensajeCarga();
          } else {
            this.getTemplate(type);
          }
        });
    } catch (error) {
      console.log('Error: ' + error);
      this.message.cerrarMensajeCarga();
    }
  }

  getTemplate(type: string) {
    try {
      const idSigmaConsulta = 'PLANTILLAS_ARBOL_FORM_DINAMICO_POR_TIPO';
      const filtro = '{"tipo": "' + type.toUpperCase() + '"}';
      this.dynamicService.getServicioSigmaConsulta(
        this.sistemaActual,
        idSigmaConsulta,
        filtro,
        '{}')
        .subscribe(data => {
          if (data.respuesta === 'true') {
            const plantilla = JSON.parse(data.datos).plantilla;

            const nodes = plantilla[0].nodes;
            const formsConfig = plantilla[0].formularios[0].definitions;

            if (nodes) {
              this.nodes = nodes;
            }
            if (formsConfig) {
              this.formsConfig = formsConfig;
            }
          } else {
            this.nodes = [];
            this.formsConfig = [];
          }
          this.message.cerrarMensajeCarga();
        });
    } catch (error) {
      console.log('Error: ' + error);
      this.message.cerrarMensajeCarga();
    }
  }


  setForm(dataParam) {

    const data = JSON.parse(dataParam);
    let encontro = false;
    if (data.idFormDynamic) {
      const idFormDynamic = data.idFormDynamic;
      for (const i of this.formsConfig) {
        if (i.id === idFormDynamic) {
          this.fields = i.definicion;
          encontro = true;
          let formsAux = JSON.parse(JSON.stringify(data));
          const auxId = formsAux['id'];
          formsAux = this.clearConfigJson(formsAux); // Quita las llaves de configuración del modelo
          if (this.idModelCurrent !== auxId) { // Valida si es un nuevo nodo del arbol seleccionado
            this.idFormDynamicCurrent = idFormDynamic;
            this.idModelCurrent = auxId;
            if (JSON.stringify(formsAux) === JSON.stringify({})) {
              this.dynamicService.dynamicForm.renderizarForm(this.fields);
            } else {
              this.dynamicService.dynamicForm.renderizarFormValues(this.fields, formsAux);
            }
          }
          break;
        }
      }
    } else {
      this.idFormDynamicCurrent = undefined;
      this.idModelCurrent = undefined;
    }
    if (!encontro && data.idFormDynamic) {
      this.message.retornarMensajeFuncion('error', 'Valide', 'No hay configuración para este formulario: '
        + data.idFormDynamic, '', null, '', null);
      this.idFormDynamicCurrent = undefined;
      this.idModelCurrent = undefined;
    }
  }

  clearConfigJson(formsAux) {
    delete formsAux['children'];
    delete formsAux['alias'];
    delete formsAux['id'];
    delete formsAux['idFormDynamic'];
    delete formsAux['name'];
    delete formsAux['cloneChild'];
    delete formsAux['canDelete'];
    delete formsAux['canDeleteChild'];
    delete formsAux['canDeleteChildLength'];
    delete formsAux['alowChangeAlias'];
    delete formsAux['clone'];
    return formsAux;
  }

  get nodesIsEmpty() {
    return !this.nodes || this.nodes.length === 0;
  }

  delEvent() {
    if (this.config.pk) {
      this.eventAction(this.configService.nameObject, this.configService.actionDelete, this.config,
        'eliminar arbol' + this.config.pk);
    }
  }

  eventAction(nombreObjeto, accion, pk, message) {

    const htmlMensaje = '<br><br><B></B>';
    const datosPeticion = '{}';
    const data = { pk: pk };

    const parameters = {
      component: this,
      sistema: '',
      perfil: '',
      nombreObjeto: nombreObjeto,
      accion: accion,
      jsonData: data,
      datosPeticion: datosPeticion
    }

    if (message) {
      this.message.retornarMensajeFuncion('confirmacion', '¿Desea ' + message + '?', '', htmlMensaje, this, 'getAction', parameters);
    } else {
      this.getAction(parameters);
    }
  }

  getAction(parameters) {
    parameters.component.message.retornarMensajeFuncion('loading', 'Procesando tu solicitud...', '', '', null, '', null);
    let json = JSON.stringify(parameters.jsonData);
    json = json.replace(/null/g, '"undefined"');
    parameters.component.dynamicService.getServicioRealizarAccion(
      parameters.sistema,
      parameters.nombreObjeto,
      parameters.accion,
      parameters.jsonData,
      parameters.datosPeticion).subscribe(res => {
        const dataRespuesta = res;
        if (dataRespuesta.respuesta === 'true') {
          parameters.component.nodes = [];
          parameters.component.plantilla = '';
          parameters.component.nodeGrupoActual = undefined;
          parameters.component.nodeActual = undefined;
          parameters.component.message.cerrarMensajeCarga();
          parameters.component.message.retornarMensaje('success', 'Exito', '' + dataRespuesta.noticia, '');
        } else {
          parameters.component.message.cerrarMensajeCarga();
          parameters.component.message.retornarMensaje('error', 'Error', dataRespuesta.noticia, '');
          console.log(dataRespuesta.error);
        }
      });
  }
}

// tslint:disable-next-line: class-name
export class fields {
  // {
  //   type: 'text',
  //   name: 'firstName',
  //   label: 'First Name',
  //   value: '',
  //   required: true,
  //   ancho: 'col-md-12'
  // },
  // {
  //   type: 'file',
  //   name: 'picture',
  //   label: 'Picture',
  //   required: true,
  //   onUpload: this.onUpload.bind(this),
  //   ancho: 'col-md-12'
  // },
  // {
  //   type: 'select',
  //   name: 'country',
  //   label: 'Country',
  //   value: 'in',
  //   required: true,
  //   ancho: 'col-md-12',
  //   options: [
  //     { id: 'in', text: 'India' },
  //     { id: 'us', text: 'USA' }
  //   ]
  // },
  // {
  //   type: 'radio',
  //   name: 'countryradio',
  //   label: 'Country',
  //   value: 'm',
  //   required: true,
  //   ancho: 'col-md-12',
  //   options: [
  //     { id: 'm', text: 'Male' },
  //     { id: 'f', text: 'Female' }
  //   ]
  // },
  // {
  //   type: 'checkbox',
  //   name: 'hobby',
  //   label: 'Hobby',
  //   required: true,
  //   ancho: 'col-md-12',
  //   options: [
  //     { id: 'f', text: 'Fishing' },
  //     { id: 'c', text: 'Cooking' }
  //   ]
  // },
  // {
  //   'type': 'button',
  //   'typeForm': 'tree-node',
  //   'name': 'Guardar',
  //   'class': 'btn btn-raised btn-primary p-1 m-1 ',
  //   'nameObject': 'informeExpediente',
  //   'action': 'AdicionarInforme',
  //   'ancho': 'col-md-6'
  // }
}

